import React, { useContext } from "react";
import { Formik, useFormikContext } from "formik";
import { BadgeCheckIcon } from "@heroicons/react/solid";
import { QuoteContext } from "../../context/quote";
import { getEnv } from "../../utils/helperFunctions";

export default function ReceiptInfo() {
  const { values, errors, touched } = useFormikContext();
  const { quote, purchase } = useContext(QuoteContext);

  const price = () => {
    if (values.paymentMethod === "Monthly") {
      return `${quote.monthlyPremiumWithTax}:- i månaden.`;
    }

    return `${quote.annualPremiumWithTax}:- per år.`;
  };

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      {/* <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Produktförsäkring 651 865 668
        </h3>
        <p className="max-w-2xl mt-1 text-sm text-gray-500">
          Kom ihåg att du måste betala inom 3 dagar för att försäkringen ska
          gälla!
        </p>
      </div> */}
      <div className="px-4 py-5 border-t border-gray-200 sm:px-6">
        {/* Policy data */}

        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Produkt</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {quote.brand} {values.product.name}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Kostnad och betalning
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{price()}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Produktpris</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {values.priceRange}:-
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Försäkringsperiod
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {quote.startDate}-{quote.endDate}
            </dd>
          </div>
        </dl>
        {/* Customer data */}
        <div className="relative my-6">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-start">
            <span className="pr-2 text-sm text-gray-500 bg-white">
              Kunduppgifter
            </span>
          </div>
        </div>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Personnummer</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {values.socialSecurityNumber}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Mobilnummer</dt>
            <dd className="mt-1 text-sm text-gray-900">{values.phone}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Email</dt>
            <dd className="mt-1 text-sm text-gray-900">{values.email}</dd>
          </div>
          {/* <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">About</dt>
            <dd className="mt-1 text-sm text-gray-900">
              Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim
              incididunt cillum culpa consequat. Excepteur qui ipsum aliquip
              consequat sint. Sit id mollit nulla mollit nostrud in ea officia
              proident. Irure nostrud pariatur mollit ad adipisicing
              reprehenderit deserunt qui eu.
            </dd>
          </div> */}
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">
              Försäkringen omfattar
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ul className="border border-gray-200 divide-y divide-gray-200 rounded-md">
                {Object.entries(quote.coverMap).map((cover) => {
                  return (
                    <li
                      key={cover[1].extId}
                      className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                    >
                      <div className="flex items-center flex-1 w-0">
                        <BadgeCheckIcon
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="flex-1 w-0 ml-2 truncate">
                          {cover[1].coverageCaption}
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

import axios from "axios";
import { useQuery } from "react-query";
import { useAuth } from "../context/auth";

export default function useProducts() {
  const { user } = useAuth();

  return useQuery(
    "products",
    () =>
      axios
        .get(`${process.env.REACT_APP_AKKA_ENDPOINT}portal/user/product`, {
          headers: {
            Authorization: "Bearer " + user.token,
            credentials: "include",
          },
        })
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
}

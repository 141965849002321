import React from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useAuth } from "../../context/auth";
import GlobalLoader from "../GlobalLoader/GlobalLoader";

export const UserMenu = () => {
  const { user, signOutUser } = useAuth();

  return (
    <div className="flex items-center ml-4 md:ml-6">
      <GlobalLoader />
      <div className="relative ml-3">
        <Menu as="div" className="relative ml-3">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="flex items-center max-w-xs text-sm rounded-sm bg-mist focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <div>
                    <p className="text-sm font-medium text-gray-600 group-hover:text-gray-800">
                      {user.email}
                    </p>
                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                      Inställningar
                    </p>
                  </div>
                </Menu.Button>
              </div>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="absolute right-0 z-50 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                >
                  <div className="px-4 py-3">
                    <p className="text-sm leading-5">Inloggad som</p>
                    <p className="text-sm font-medium leading-5 text-gray-900 truncate">
                      {user.email}
                    </p>
                  </div>
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/#"
                          onClick={signOutUser}
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                        >
                          Logga ut
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
};

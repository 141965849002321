import React, { useState } from "react";
import axios from "axios";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { AuthContext } from "./context/auth";
import Login from "./Pages/Login/Login";
import Layout from "./components/Layout/Layout";
import { HelmetProvider } from "react-helmet-async";
import AccountSettings from "./Pages/User/AccountSettings";
import EnvironmentBadge from "./components/Layout/EnvironmentBadge";
import QuoteWizard from "./Pages/Quote/quoteWizard";
import FreePeriodQuote from "./Pages/Quote/freePeriod";
import { getEnv } from "./utils/helperFunctions";
// import GlobalMessageBadge from "./components/Layout/GlobalMessageBadge";

function Auth() {
  const existingTokens = JSON.parse(localStorage.getItem("token"));
  const [authToken, setAuthToken] = useState(existingTokens);

  // We store this interceptor here to be able to update the state of auth...
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      if (401 === error.response.status) {
        //If 401 is from search, ignore to sign out due to possible permissions problem in Akka.
        if (error.response.config.url.includes("user/product/cat-search/"))
          return;

        console.log("We should refetch token or log out user...");

        signOutUser();
      }
      return Promise.reject(error);
    }
  );

  const signInUser = (data) => {
    localStorage.setItem("token", JSON.stringify(data));
    setAuthToken(data);
  };

  const signOutUser = () => {
    localStorage.removeItem("token");
    setAuthToken(null);
    window.location.reload();
  };

  return (
    <AuthContext.Provider value={{ user: authToken, signInUser, signOutUser }}>
      <HelmetProvider>
        {!authToken ? (
          <BrowserRouter>
            <Route path="/" component={Login}></Route>
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <Layout>
              <Switch>
                <PrivateRoute
                  path="/account"
                  comp={AccountSettings}
                ></PrivateRoute>

                {/* <PrivateRoute path="/" comp={Example}></PrivateRoute> */}
                {/* <PrivateRoute path="/" comp={Products}></PrivateRoute> */}

                {authToken.freeOffer === true ? (
                  <PrivateRoute path="/" comp={FreePeriodQuote}></PrivateRoute>
                ) : (
                  <PrivateRoute path="/" comp={QuoteWizard}></PrivateRoute>
                )}

                {/* <PrivateRoute path="/" comp={Calculate}></PrivateRoute> */}
              </Switch>
            </Layout>
          </BrowserRouter>
        )}
        {getEnv() !== "prod" && <EnvironmentBadge />}
        {/* <GlobalMessageBadge /> */}
        {/* <ReactQueryDevtools /> */}
      </HelmetProvider>
    </AuthContext.Provider>
  );
}

export default Auth;

// const CheckBackLater = () => {
//   const { user, signOutUser } = useAuth();
//   const [showNotification, setShowNotification] = useState(true);

//   useEffect(() => {
//     setTimeout(() => {
//       setShowNotification(true);
//     }, 600);
//   }, [showNotification]);

//   return (
//     <Transition
//       appear={true}
//       show={showNotification}
//       enter="transform ease-out delay-200 duration-300 transition"
//       enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
//       enterTo="translate-y-0 opacity-100 sm:translate-x-0"
//       leave="transition ease-in duration-100"
//       leaveFrom="opacity-100"
//       leaveTo="opacity-0"
//     >
//       <div className="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
//         <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
//           <div className="p-4">
//             <div className="flex items-start">
//               <div className="flex-shrink-0">
//                 <svg
//                   className="w-6 h-6 text-green-400"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke="currentColor"
//                   aria-hidden="true"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
//                   />
//                 </svg>
//               </div>
//               <div className="ml-3 w-0 flex-1 pt-0.5">
//                 <p className="text-sm font-medium text-gray-900">
//                   Welcome {user.name}!
//                 </p>
//                 <p className="mt-1 text-sm text-gray-500">
//                   At this time there is not much here.
//                   <br />
//                   <br />
//                   You can sign out or verify that you stay signed in by pressing
//                   the buttons below.
//                 </p>
//                 <div className="mt-2">
//                   <button
//                     onClick={signOutUser}
//                     className="text-sm font-medium text-indigo-600 bg-white rounded-md hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                   >
//                     Sign out
//                   </button>
//                   <button
//                     onClick={() => window.location.reload(true)}
//                     className="ml-6 text-sm font-medium text-gray-700 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                   >
//                     Reload page
//                   </button>
//                 </div>
//               </div>
//               <div className="flex flex-shrink-0 ml-4">
//                 <button
//                   onClick={() => setShowNotification(!showNotification)}
//                   className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                 >
//                   <span className="sr-only">Close</span>

//                   <svg
//                     className="w-5 h-5"
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 20 20"
//                     fill="currentColor"
//                     aria-hidden="true"
//                   >
//                     <path
//                       fillRule="evenodd"
//                       d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
//                       clipRule="evenodd"
//                     />
//                   </svg>
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </Transition>
//   );
// };

import React from "react";
import { Disclosure } from "@headlessui/react";
import { useAuth } from "../../context/auth";

export const UserMenuSmall = () => {
  const { user, signOutUser } = useAuth();

  return (
    <Disclosure.Panel className="sm:hidden">
      <div className="pt-4 pb-3 border-t border-gray-200">
        <div className="flex items-center px-4">
          <div>
            <div className="text-base font-medium text-gray-800">
              Inloggad som
            </div>
            <div className="text-sm font-medium text-gray-500">
              {user.email}
            </div>
          </div>
        </div>
        <div className="mt-3 space-y-1">
          <a
            href="/#"
            onClick={signOutUser}
            className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
          >
            Logga ut
          </a>
        </div>
      </div>
    </Disclosure.Panel>
  );
};

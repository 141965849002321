import axios from "axios";
import { useMutation } from "react-query";
import { useAuth } from "../context/auth";

export default function useAuthentication() {
  const { signInUser } = useAuth();

  return useMutation(
    (values) =>
      axios
        .post(`${process.env.REACT_APP_AKKA_ENDPOINT}portal/signin`, values, {
          headers: { credentials: "include" },
        })
        .then((res) => res.data),
    {
      onError: (error, _newPost, rollback) => {
        console.error(error);
        // if (rollback) rollback();
      },
      onSuccess: (data) => {
        signInUser(data);
      },
    }
  );
}

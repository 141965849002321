import React from "react";
import { useFormik } from "formik";
import { Transition } from "@headlessui/react";
import { useAuth } from "../../../context/auth";
import { isEmail, isSwedishMobile } from "../../../utils/validations";
import {
  EmailField,
  FirstNameField,
  LastNameField,
  MobileField,
} from "./Fields";

export const AccountTab = () => {
  const { user } = useAuth();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user.email,
      firstName: user.name,
      lastName: "",
      mobile: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.email) {
        errors.email = "Email field is required";
      } else if (!isEmail(values.email)) {
        errors.email = "Invalid email format";
      }
      if (!values.mobile) {
        errors.mobile = "Mobile field is required";
      } else if (!isSwedishMobile(values.mobile)) {
        errors.mobile = "Invalid mobile format";
      }

      if (!values.firstName) {
        errors.firstName = "First name field is required";
      }

      if (!values.lastName) {
        errors.lastName = "Last name field is required";
      }

      return errors;
    },
    onSubmit: (values) => {
      console.log("put to api");
    },
  });

  return (
    <form
      className="divide-y divide-gray-200 lg:col-span-9"
      onSubmit={formik.handleSubmit}
    >
      <Transition
        show={true}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="px-4 py-6 sm:p-6 lg:pb-8">
          <div>
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              Account
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Update and change you account settings.
            </p>
          </div>

          <div className="grid grid-cols-12 gap-6 mt-6">
            <EmailField formik={formik} />

            <MobileField formik={formik} />

            <FirstNameField formik={formik} />

            <LastNameField formik={formik} />

            <div>
              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
                {user.partnerName}
              </span>
            </div>

            <div>
              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                {user.userRole.name}
              </span>
            </div>
          </div>
        </div>

        <div className="pt-6 divide-y divide-gray-200">
          <div className="px-4 sm:px-6">
            <div>
              <h2 className="text-lg font-medium leading-6 text-gray-900">
                Settings
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Handle settings for your account
              </p>
            </div>
            <ul className="mt-2 divide-y divide-gray-200 opacity-50 cursor-not-allowed">
              <li className="flex items-center justify-between py-4">
                <div className="flex flex-col">
                  <p
                    className="text-sm font-medium text-gray-900"
                    id="privacy-option-1-label"
                  >
                    Use 2FA
                  </p>
                  <p
                    className="text-sm text-gray-500"
                    id="privacy-option-1-description"
                  >
                    When this option is enabled, you need to enter a pin code
                    sent to you mobile to be able to sign in.
                  </p>
                </div>
                {/* <!-- Enabled: "bg-teal-500", Not Enabled: "bg-gray-200" --> */}
                <button
                  type="button"
                  className="relative inline-flex flex-shrink-0 h-6 ml-4 transition-colors duration-200 ease-in-out bg-gray-200 border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
                  aria-pressed="true"
                  aria-labelledby="privacy-option-1-label"
                  aria-describedby="privacy-option-1-description"
                >
                  <span className="sr-only">Use setting</span>
                  {/* <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" --> */}
                  <span
                    aria-hidden="true"
                    className="inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full shadow ring-0"
                  ></span>
                </button>
              </li>
              <li className="flex items-center justify-between py-4 ">
                <div className="flex flex-col">
                  <p
                    className="text-sm font-medium text-gray-900"
                    id="privacy-option-2-label"
                  >
                    Dark theme
                  </p>
                  <p
                    className="text-sm text-gray-500"
                    id="privacy-option-2-description"
                  >
                    Force dark theme regardless of color scheme selected in os.
                  </p>
                </div>
                {/* <!-- Enabled: "bg-teal-500", Not Enabled: "bg-gray-200" --> */}

                <button
                  type="button"
                  className="relative inline-flex flex-shrink-0 h-6 ml-4 transition-colors duration-200 ease-in-out bg-gray-200 border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
                  aria-pressed="true"
                  aria-labelledby="privacy-option-1-label"
                  aria-describedby="privacy-option-1-description"
                >
                  <span className="sr-only">Use setting</span>
                  {/* <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" --> */}
                  <span
                    aria-hidden="true"
                    className="inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full shadow ring-0"
                  ></span>
                </button>
              </li>
            </ul>
          </div>
          <div className="flex justify-end px-4 py-4 mt-4 sm:px-6">
            <button
              type="button"
              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex justify-center px-4 py-2 ml-5 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-light-blue-700 hover:bg-light-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
            >
              Save
            </button>
          </div>
        </div>
      </Transition>
    </form>
  );
};

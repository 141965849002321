import React, { useEffect, useContext } from "react";
import SelectMenu from "../SelectMenu/selectMenu";
import { useFormikContext, ErrorMessage, Field } from "formik";
import DynamicSelectMenu from "../SelectMenu/DynamicSelectMenu";
import DynamicSelects from "../SelectMenu/DynamicSelects";
import { Transition, RadioGroup } from "@headlessui/react";
import { HomeIcon } from "@heroicons/react/solid";
import { AddSpacesToInteger } from "../../utils/helperFunctions";
import ReceiptInfo from "./receiptInfo";
import QRCode from "qrcode.react";
import { QuoteContext } from "../../context/quote";

function Step3() {
  const { values, errors, touched } = useFormikContext();
  const { purchase } = useContext(QuoteContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="px-4 py-5 md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <h3 className="text-base font-medium leading-6 text-gray-800">
          Bekräftelse produktförsäkring
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Vi har nu mottagit förfrågan om försäkring. Observera att kunden måste
          betala försäkringen för att den ska börja gälla. Kunden får en länk
          för att registrera betalning både per sms och per mail.
        </p>
        <section className="max-w-xs mt-8 lg:col-start-3 lg:col-span-1">
          <div className="px-4 py-5 bg-gray-100 shadow sm:rounded-lg sm:px-6">
            <h2
              id="timeline-title"
              className="text-lg font-medium text-center text-gray-900"
            >
              Betala försäkringen
            </h2>

            <div className="flex justify-center mt-6">
              <QRCode
                value={purchase?.reminderUrl}
                fgColor="#332B54"
                level="M"
                // includeMargin={true}
              />
            </div>
            <p className="mt-4 text-xs text-gray-500">
              Kunden kan scanna QR-koden med mobilkameran för att gå till
              betalning. <br />
              <br />
              Eller så kan ni öppna sidan för betalning på denna dator med Gå
              till betalning-knappen.
            </p>
            <div className="flex flex-col mt-6 justify-stretch">
              <button
                type="button"
                onClick={() => window.open(purchase.reminderUrl, "_blank")}
                className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Gå till betalning
              </button>
            </div>
          </div>
        </section>

        <p className="mt-4 text-sm text-gray-500">
          Ni hittar mer information om försäkringen i{" "}
          <a
            href="https://evoli.se/forkopsinformation/Forkopsinformation-Produktforsakring.pdf"
            target="_blank"
            className="font-medium text-brand hover:text-brand-light"
          >
            Förköpsinformation
          </a>{" "}
          och{" "}
          <a
            href="https://evoli.se/produktfaktablad/IPID_produkt_2021-05-01.pdf"
            target="_blank"
            className="font-medium text-brand hover:text-brand-light"
          >
            Produktfaktablad
          </a>
          .
        </p>
      </div>
      <div className="mt-5 md:mt-0 md:col-span-2">
        {/* <Breadcrumbs data={values} /> */}
        <div className="space-y-8">
          <ReceiptInfo />
        </div>
      </div>
    </div>
  );
}

export default Step3;

function Breadcrumbs({ data }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {/* <li>
          <div>
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 w-5 h-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li> */}
        {data.selectState.map((page, index) => (
          <li key={page.key ?? page.name}>
            <div className="flex items-center">
              {index !== 0 && (
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
              )}
              <a
                href="#"
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {page.key ?? page.name}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

function Summary() {
  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Product</dt>
        <dd className="mt-1 text-sm text-gray-900">123</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Product pris</dt>
        <dd className="mt-1 text-sm text-gray-900">6000</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Personnummer</dt>
        <dd className="mt-1 text-sm text-gray-900">12121212</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Startdatum</dt>
        <dd className="mt-1 text-sm text-gray-900">2020-05-05</dd>
      </div>
      {/* <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          Salary expectation
        </dt>
        <dd className="mt-1 text-sm text-gray-900">$120,000</dd>
      </div> */}
      {/* <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">About</dt>
        <dd className="mt-1 text-sm text-gray-900">
          Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt
          cillum culpa consequat. Excepteur qui ipsum aliquip consequat sint.
          Sit id mollit nulla mollit nostrud in ea officia proident. Irure
          nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
        </dd>
      </div> */}
    </dl>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

function PasswordNavigation({ currentTab }) {
  return (
    <aside className="py-6 lg:col-span-3">
      <nav>
        <NavLink
          exact
          to="/account"
          className="flex items-center px-3 py-2 text-sm font-medium text-gray-900 border-l-4 border-transparent hover:bg-gray-50 hover:text-gray-900 group"
          activeClassName="bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700"
          aria-current="page"
        >
          <svg
            className={`${
              currentTab === "/account"
                ? "text-teal-500 group-hover:text-teal-500"
                : "text-gray-400 group-hover:text-gray-500"
            } flex-shrink-0 w-6 h-6 mr-3 -ml-1`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span className="truncate">Account</span>
        </NavLink>

        <NavLink
          to="/account/password"
          className="flex items-center px-3 py-2 mt-1 text-sm font-medium text-gray-900 border-l-4 border-transparent hover:bg-gray-50 hover:text-gray-900 group"
          activeClassName="bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700"
        >
          <svg
            className={`${
              currentTab === "/account/password"
                ? "text-teal-500 group-hover:text-teal-500"
                : "text-gray-400 group-hover:text-gray-500"
            } flex-shrink-0 w-6 h-6 mr-3 -ml-1`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
            />
          </svg>
          <span className="truncate">Password</span>
        </NavLink>

        <a
          href="/#"
          className="flex items-center px-3 py-2 mt-1 text-sm font-medium text-gray-900 border-l-4 border-transparent opacity-50 cursor-not-allowed hover:bg-gray-50 hover:text-gray-900 group"
        >
          <svg
            className="flex-shrink-0 w-6 h-6 mr-3 -ml-1 text-gray-400 group-hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
            />
          </svg>
          <span className="truncate">Billing</span>
        </a>

        <a
          href="/#"
          className="flex items-center px-3 py-2 mt-1 text-sm font-medium text-gray-900 border-l-4 border-transparent opacity-50 cursor-not-allowed hover:bg-gray-50 hover:text-gray-900 group"
        >
          <svg
            className="flex-shrink-0 w-6 h-6 mr-3 -ml-1 text-gray-400 group-hover:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
            />
          </svg>
          <span className="truncate">Integrations</span>
        </a>
      </nav>
    </aside>
  );
}

export default PasswordNavigation;

PasswordNavigation.propTypes = {
  currentTab: PropTypes.string.isRequired,
};

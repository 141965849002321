import AppBar from "./AppBar";

export default function Layout({ children }) {
  return (
    <div className="min-h-screen bg-gray-200">
      <AppBar />

      <div className="py-2 ">{children}</div>
    </div>
  );
}

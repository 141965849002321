import React from "react";
import PropTypes from "prop-types";
import { propTypes } from "qrcode.react";

export const evoli = ({ textColor, backgroundColor }) => (
  <svg
    className="w-auto h-8 mx-auto"
    width="156"
    height="57"
    viewBox="0 0 156 57"
    fill={backgroundColor}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.736244 27.2365C3.74128 18.8084 11.7922 12.7656 21.265 12.7656C27.3881 12.7656 32.9271 15.2966 36.8807 19.3588C38.4252 20.9457 38.4081 23.4792 36.8423 25.045L20.4223 41.4655C18.8414 43.0465 16.2781 43.0465 14.6972 41.4655C13.1162 39.8845 13.1162 37.3212 14.6972 35.7402L26.937 23.5001C27.4074 23.0296 27.295 22.239 26.6841 21.9754C25.0228 21.2588 23.1914 20.8624 21.265 20.8624C15.3223 20.8624 10.2542 24.6504 8.36253 29.9558C7.61165 32.0617 5.29572 33.1603 3.18977 32.4094C1.08382 31.6585 -0.0146441 29.3424 0.736244 27.2365ZM3.18977 36.7025C5.29572 35.9516 7.61164 37.0501 8.36253 39.1561C10.2542 44.4615 15.3223 48.2495 21.265 48.2495C27.2078 48.2495 32.2759 44.4615 34.1675 39.1561C34.9184 37.0501 37.2343 35.9516 39.3403 36.7025C41.4462 37.4534 42.5447 39.7694 41.7938 41.8754C38.7887 50.3035 30.7379 56.3463 21.265 56.3463C11.7922 56.3463 3.74128 50.3035 0.736244 41.8754C-0.0146441 39.7694 1.08382 37.4534 3.18977 36.7025Z"
      fill={textColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.9108 13.188C43.9138 12.1947 46.3428 13.0133 47.3361 15.0164L61.4803 43.5419L75.8726 14.9925C76.8793 12.9961 79.3136 12.1934 81.3101 13.1999C83.3067 14.2064 84.1088 16.6408 83.1029 18.6373L65.0657 54.4169C64.3755 55.7862 62.9707 56.6479 61.4371 56.6429C59.9042 56.6378 58.5048 55.7668 57.8239 54.3929L40.0823 18.6134C39.0891 16.6103 39.9076 14.1812 41.9108 13.188Z"
      fill={textColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.8003 14.1855C97.9067 13.4346 100.222 14.5331 100.973 16.639C101.724 18.745 100.626 21.061 98.52 21.8119C93.2145 23.7036 89.4266 28.7719 89.4266 34.7148C89.4266 40.6578 93.2144 45.726 98.52 47.6177C100.626 48.3686 101.724 50.6846 100.973 52.7906C100.222 54.8966 97.9067 55.9951 95.8003 55.2442C87.3727 52.2391 81.3301 44.1879 81.3301 34.7148C81.3301 25.2417 87.3727 17.1906 95.8003 14.1855ZM105.266 16.639C106.017 14.5331 108.333 13.4346 110.439 14.1855C118.867 17.1906 124.909 25.2417 124.909 34.7148C124.909 44.1879 118.867 52.239 110.439 55.2442C108.333 55.9951 106.017 54.8966 105.266 52.7906C104.515 50.6846 105.614 48.3687 107.72 47.6177C113.025 45.726 116.813 40.6578 116.813 34.7148C116.813 28.7719 113.025 23.7036 107.72 21.812C105.614 21.061 104.515 18.745 105.266 16.639Z"
      fill={textColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.99 4.98589C127.99 2.75005 129.803 0.9375 132.039 0.9375C134.275 0.9375 136.087 2.75005 136.087 4.98589V43.4268C136.087 44.3194 136.104 45.0668 136.177 45.6974C136.251 46.3283 136.377 46.8103 136.576 47.1844C136.713 47.4442 136.881 47.6337 137.189 47.7983C137.541 47.9857 138.286 48.2493 139.727 48.2493C141.963 48.2493 143.775 50.0619 143.775 52.2977C143.775 54.5335 141.963 56.3461 139.727 56.3461C137.324 56.3461 135.186 55.9077 133.374 54.9399C131.52 53.949 130.246 52.5301 129.424 50.9796C127.981 48.2608 127.987 45.1684 127.99 43.6369L127.99 43.6168V4.98589Z"
      fill={textColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M145.71 5.57924C145.71 3.01677 147.787 0.939453 150.35 0.939453C152.912 0.939453 154.989 3.01677 154.989 5.57924C154.989 8.14171 152.912 10.219 150.35 10.219C147.787 10.219 145.71 8.14171 145.71 5.57924ZM146.301 16.8158C146.301 14.58 148.114 12.7674 150.35 12.7674C152.586 12.7674 154.398 14.58 154.398 16.8158V52.2997C154.398 54.5355 152.586 56.3481 150.35 56.3481C148.114 56.3481 146.301 54.5355 146.301 52.2997V16.8158Z"
      fill={textColor}
    />
  </svg>
);

evoli.propTypes = {
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
};

evoli.defaultProps = {
  textColor: "#5A37EA",
  backgroundColor: "none",
};

export const EvoliTagline = ({ height, textColor, backgroundColor }) => {
  return (
    <svg
      className={`w-auto h-11 mx-auto `}
      width="155"
      height="81"
      viewBox="0 0 155 81"
      fill={backgroundColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.236244 26.7365C3.24128 18.3084 11.2922 12.2656 20.765 12.2656C26.8881 12.2656 32.4271 14.7966 36.3807 18.8588C37.9252 20.4457 37.9081 22.9792 36.3423 24.545L19.9223 40.9655C18.3414 42.5465 15.7781 42.5465 14.1972 40.9655C12.6162 39.3845 12.6162 36.8212 14.1972 35.2402L26.437 23.0001C26.9074 22.5296 26.795 21.739 26.1841 21.4754C24.5228 20.7588 22.6914 20.3624 20.765 20.3624C14.8223 20.3624 9.75417 24.1504 7.86253 29.4558C7.11165 31.5617 4.79572 32.6603 2.68977 31.9094C0.583819 31.1585 -0.514644 28.8424 0.236244 26.7365ZM2.68977 36.2025C4.79572 35.4516 7.11164 36.5501 7.86253 38.6561C9.75417 43.9615 14.8223 47.7495 20.765 47.7495C26.7078 47.7495 31.7759 43.9615 33.6675 38.6561C34.4184 36.5501 36.7343 35.4516 38.8403 36.2025C40.9462 36.9534 42.0447 39.2694 41.2938 41.3754C38.2887 49.8035 30.2379 55.8463 20.765 55.8463C11.2922 55.8463 3.24128 49.8035 0.236244 41.3754C-0.514644 39.2694 0.58382 36.9534 2.68977 36.2025Z"
        fill={textColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.4106 12.688C43.4137 11.6947 45.8427 12.5133 46.8359 14.5164L60.9802 43.0419L75.3725 14.4925C76.3791 12.4961 78.8135 11.6934 80.81 12.6999C82.8065 13.7064 83.6087 16.1408 82.6027 18.1373L64.5656 53.9169C63.8753 55.2862 62.4706 56.1479 60.937 56.1429C59.4041 56.1378 58.0047 55.2668 57.3238 53.8929L39.5822 18.1134C38.589 16.1103 39.4075 13.6812 41.4106 12.688Z"
        fill={textColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.3003 13.6855C97.4067 12.9346 99.7223 14.0331 100.473 16.139C101.224 18.245 100.126 20.561 98.02 21.3119C92.7145 23.2036 88.9266 28.2719 88.9266 34.2148C88.9266 40.1578 92.7144 45.226 98.02 47.1177C100.126 47.8686 101.224 50.1846 100.473 52.2906C99.7223 54.3966 97.4067 55.4951 95.3003 54.7442C86.8727 51.7391 80.8301 43.6879 80.8301 34.2148C80.8301 24.7417 86.8727 16.6906 95.3003 13.6855ZM104.766 16.139C105.517 14.0331 107.833 12.9346 109.939 13.6855C118.367 16.6906 124.409 24.7417 124.409 34.2148C124.409 43.6879 118.367 51.739 109.939 54.7442C107.833 55.4951 105.517 54.3966 104.766 52.2906C104.015 50.1846 105.114 47.8687 107.22 47.1177C112.525 45.226 116.313 40.1578 116.313 34.2148C116.313 28.2719 112.525 23.2036 107.22 21.312C105.114 20.561 104.015 18.245 104.766 16.139Z"
        fill={textColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.49 4.48589C127.49 2.25005 129.303 0.4375 131.539 0.4375C133.775 0.4375 135.587 2.25005 135.587 4.48589V42.9268C135.587 43.8194 135.604 44.5668 135.677 45.1974C135.751 45.8283 135.877 46.3103 136.076 46.6844C136.213 46.9442 136.381 47.1337 136.689 47.2983C137.041 47.4857 137.786 47.7493 139.227 47.7493C141.463 47.7493 143.275 49.5619 143.275 51.7977C143.275 54.0335 141.463 55.8461 139.227 55.8461C136.824 55.8461 134.686 55.4077 132.874 54.4399C131.02 53.449 129.746 52.0301 128.924 50.4796C127.481 47.7608 127.487 44.6684 127.49 43.1369L127.49 43.1168V4.48589Z"
        fill={textColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.21 5.07924C145.21 2.51677 147.287 0.439453 149.85 0.439453C152.412 0.439453 154.489 2.51677 154.489 5.07924C154.489 7.64171 152.412 9.71902 149.85 9.71902C147.287 9.71902 145.21 7.64171 145.21 5.07924ZM145.801 16.3158C145.801 14.08 147.614 12.2674 149.85 12.2674C152.086 12.2674 153.898 14.08 153.898 16.3158V51.7997C153.898 54.0355 152.086 55.8481 149.85 55.8481C147.614 55.8481 145.801 54.0355 145.801 51.7997V16.3158Z"
        fill={textColor}
      />
      <path
        d="M6.99842 76.4446C6.99842 76.7207 7.22228 76.9446 7.49842 76.9446H8.81841C9.09455 76.9446 9.31841 76.7207 9.31841 76.4446V67.1475H11.8279C12.1041 67.1475 12.3279 66.9236 12.3279 66.6475V65.5933C12.3279 65.3171 12.1041 65.0933 11.8279 65.0933H4.49609C4.21995 65.0933 3.99609 65.3171 3.99609 65.5933V66.6475C3.99609 66.9236 4.21995 67.1475 4.49609 67.1475H6.99842V76.4446Z"
        fill={textColor}
      />
      <path
        d="M11.9389 76.4446C11.9389 76.7207 12.1627 76.9446 12.4389 76.9446H13.6439C13.9201 76.9446 14.1439 76.7207 14.1439 76.4446V72.8361C14.1439 72.5632 14.2229 72.3094 14.381 72.0748C14.5438 71.8353 14.7521 71.6414 15.0058 71.493C15.2644 71.3445 15.5374 71.2703 15.8247 71.2703C16.057 71.2703 16.2819 71.3075 16.4994 71.382C16.7825 71.479 17.1191 71.3937 17.2517 71.1255L17.7215 70.1748C17.8353 69.9445 17.756 69.6624 17.5158 69.5713C17.4228 69.5359 17.3235 69.5037 17.2181 69.4747C16.9451 69.398 16.6794 69.3597 16.4208 69.3597C15.9659 69.3597 15.5397 69.4723 15.1423 69.6973C14.7497 69.9176 14.4169 70.2336 14.1439 70.6454V70.0896C14.1439 69.8134 13.9201 69.5896 13.6439 69.5896H12.4389C12.1627 69.5896 11.9389 69.8134 11.9389 70.0896V76.4446Z"
        fill={textColor}
      />
      <path
        d="M19.4873 80.3158C19.4023 80.4821 19.5231 80.6795 19.7099 80.6795H21.5155C21.7039 80.6795 21.8763 80.5737 21.9615 80.4057L27.0783 70.3157C27.247 69.9831 27.0053 69.5896 26.6324 69.5896H25.2406C25.0444 69.5896 24.8663 69.7044 24.7852 69.8831L22.9646 73.8991L20.8496 69.8577C20.7634 69.6929 20.5927 69.5896 20.4066 69.5896H19.0219C18.6427 69.5896 18.4015 69.9952 18.5826 70.3283L21.6789 76.0252L19.4873 80.3158Z"
        fill={textColor}
      />
      <path
        d="M31.1683 80.895C32.1404 80.895 32.9424 80.7322 33.5745 80.4066C34.2113 80.0858 34.6854 79.6428 34.9966 79.0778C35.3127 78.5128 35.4707 77.8687 35.4707 77.1457V70.0896C35.4707 69.8134 35.2468 69.5896 34.9707 69.5896H33.7656C33.4895 69.5896 33.2656 69.8134 33.2656 70.0896V70.4874C32.9735 70.1331 32.6072 69.8577 32.1667 69.6614C31.7262 69.4603 31.2473 69.3597 30.7302 69.3597C30.0215 69.3597 29.3894 69.5297 28.834 69.8697C28.2833 70.2097 27.8475 70.6741 27.5267 71.2631C27.2107 71.8521 27.0527 72.5201 27.0527 73.2671C27.0527 74.0284 27.2155 74.7036 27.5411 75.2926C27.8715 75.8815 28.3096 76.3436 28.8555 76.6788C29.4014 77.0092 30.0071 77.1744 30.6727 77.1744C31.1851 77.1744 31.6639 77.0691 32.1092 76.8584C32.5593 76.6477 32.9448 76.3532 33.2656 75.9749V77.0092C33.2656 77.603 33.0693 78.0818 32.6767 78.4457C32.2888 78.8097 31.7836 78.9916 31.1611 78.9916C30.7014 78.9916 30.2753 78.8911 29.8826 78.6899C29.6431 78.5673 29.4393 78.4188 29.271 78.2445C29.0901 78.0569 28.8115 77.9664 28.5773 78.0807L27.6291 78.5434C27.3875 78.6613 27.2788 78.9518 27.4196 79.1808C27.7247 79.677 28.1411 80.0665 28.6688 80.3491C29.3439 80.713 30.1771 80.895 31.1683 80.895ZM31.3335 75.2997C30.7589 75.2997 30.2872 75.1106 29.9185 74.7323C29.5546 74.3493 29.3727 73.8656 29.3727 73.2814C29.3727 72.6877 29.5594 72.1993 29.9329 71.8162C30.3112 71.4283 30.7828 71.2344 31.3479 71.2344C31.9129 71.2344 32.3822 71.4283 32.7557 71.8162C33.1292 72.1993 33.3159 72.6877 33.3159 73.2814C33.3159 73.8656 33.1292 74.3493 32.7557 74.7323C32.387 75.1106 31.9129 75.2997 31.3335 75.2997Z"
        fill={textColor}
      />
      <path
        d="M40.5672 80.895C41.5393 80.895 42.3413 80.7322 42.9734 80.4066C43.6102 80.0858 44.0843 79.6428 44.3955 79.0778C44.7116 78.5128 44.8696 77.8687 44.8696 77.1457V70.0896C44.8696 69.8134 44.6457 69.5896 44.3696 69.5896H43.1645C42.8884 69.5896 42.6645 69.8134 42.6645 70.0896V70.4874C42.3724 70.1331 42.0061 69.8577 41.5656 69.6614C41.1251 69.4603 40.6462 69.3597 40.1291 69.3597C39.4204 69.3597 38.7883 69.5297 38.2329 69.8697C37.6822 70.2097 37.2464 70.6741 36.9256 71.2631C36.6096 71.8521 36.4516 72.5201 36.4516 73.2671C36.4516 74.0284 36.6144 74.7036 36.94 75.2926C37.2704 75.8815 37.7085 76.3436 38.2544 76.6788C38.8003 77.0092 39.406 77.1744 40.0716 77.1744C40.584 77.1744 41.0628 77.0691 41.5081 76.8584C41.9582 76.6477 42.3437 76.3532 42.6645 75.9749V77.0092C42.6645 77.603 42.4682 78.0818 42.0756 78.4457C41.6877 78.8097 41.1825 78.9916 40.56 78.9916C40.1003 78.9916 39.6742 78.8911 39.2815 78.6899C39.042 78.5673 38.8382 78.4188 38.6699 78.2445C38.489 78.0569 38.2104 77.9664 37.9762 78.0807L37.028 78.5434C36.7864 78.6613 36.6777 78.9518 36.8185 79.1808C37.1237 79.677 37.54 80.0665 38.0677 80.3491C38.7428 80.713 39.576 80.895 40.5672 80.895ZM40.7324 75.2997C40.1578 75.2997 39.6861 75.1106 39.3174 74.7323C38.9535 74.3493 38.7716 73.8656 38.7716 73.2814C38.7716 72.6877 38.9583 72.1993 39.3318 71.8162C39.7101 71.4283 40.1817 71.2344 40.7468 71.2344C41.3118 71.2344 41.7811 71.4283 42.1546 71.8162C42.5281 72.1993 42.7148 72.6877 42.7148 73.2814C42.7148 73.8656 42.5281 74.3493 42.1546 74.7323C41.7859 75.1106 41.3118 75.2997 40.7324 75.2997Z"
        fill={textColor}
      />
      <path
        d="M49.4921 77.1744C49.9948 77.1744 50.4641 77.0715 50.8999 76.8656C51.3404 76.6549 51.7283 76.358 52.0634 75.9749V76.4446C52.0634 76.7207 52.2873 76.9446 52.5634 76.9446H53.7685C54.0446 76.9446 54.2685 76.7207 54.2685 76.4446V70.0896C54.2685 69.8134 54.0446 69.5896 53.7685 69.5896H52.5634C52.2873 69.5896 52.0634 69.8134 52.0634 70.0896V70.4874C51.7713 70.1331 51.4002 69.8577 50.9501 69.6614C50.5 69.4603 50.014 69.3597 49.4921 69.3597C48.7834 69.3597 48.1537 69.5321 47.603 69.8769C47.0572 70.2169 46.6286 70.6813 46.3173 71.2703C46.0061 71.8545 45.8505 72.5153 45.8505 73.2527C45.8505 74.0093 46.0133 74.6844 46.3389 75.2782C46.6645 75.8672 47.1003 76.3317 47.6461 76.6716C48.1968 77.0068 48.8121 77.1744 49.4921 77.1744ZM50.1457 75.2997C49.5759 75.2997 49.1042 75.1082 48.7307 74.7251C48.3572 74.3421 48.1705 73.856 48.1705 73.2671C48.1705 72.6781 48.3572 72.1921 48.7307 71.809C49.109 71.4259 49.5806 71.2344 50.1457 71.2344C50.7155 71.2344 51.1848 71.4259 51.5535 71.809C51.927 72.1873 52.1137 72.6733 52.1137 73.2671C52.1137 73.8608 51.9294 74.3493 51.5607 74.7323C51.1919 75.1106 50.7203 75.2997 50.1457 75.2997Z"
        fill={textColor}
      />
      <path
        d="M61.0446 76.4446C61.0446 76.7207 61.2684 76.9446 61.5446 76.9446H62.7496C63.0258 76.9446 63.2496 76.7207 63.2496 76.4446V71.2631H64.4878C64.764 71.2631 64.9878 71.0393 64.9878 70.7631V70.0896C64.9878 69.8134 64.764 69.5896 64.4878 69.5896H63.2496V67.8155C63.2496 67.3749 63.3047 67.0158 63.4148 66.7381C63.525 66.4604 63.7668 66.3215 64.1403 66.3215C64.2298 66.3215 64.3167 66.3285 64.401 66.3424C64.69 66.3903 65.0201 66.3041 65.1497 66.0413L65.4925 65.346C65.5963 65.1355 65.5416 64.8764 65.3357 64.7636C65.174 64.675 64.9958 64.6005 64.8011 64.5402C64.4611 64.4301 64.114 64.375 63.7596 64.375C62.9073 64.375 62.2417 64.6815 61.7629 65.2944C61.284 65.9025 61.0446 66.7261 61.0446 67.7652V69.5896H60.5606C60.2844 69.5896 60.0606 69.8134 60.0606 70.0896V70.7631C60.0606 71.0393 60.2844 71.2631 60.5606 71.2631H61.0446V76.4446Z"
        fill={textColor}
      />
      <path
        d="M69.9812 77.1744C70.7953 77.1744 71.5279 77.0092 72.1791 76.6788C72.8303 76.3436 73.3451 75.8839 73.7234 75.2997C74.1065 74.7108 74.298 74.0332 74.298 73.2671C74.298 72.5009 74.1065 71.8258 73.7234 71.2416C73.3451 70.6526 72.8303 70.1929 72.1791 69.8625C71.5279 69.5273 70.7953 69.3597 69.9812 69.3597C69.172 69.3597 68.4394 69.5273 67.7834 69.8625C67.1321 70.1929 66.615 70.6526 66.2319 71.2416C65.8536 71.8258 65.6645 72.5009 65.6645 73.2671C65.6645 74.0332 65.8536 74.7108 66.2319 75.2997C66.615 75.8839 67.1321 76.3436 67.7834 76.6788C68.4394 77.0092 69.172 77.1744 69.9812 77.1744ZM69.9812 75.2926C69.6077 75.2926 69.2678 75.2064 68.9613 75.034C68.6596 74.8616 68.4202 74.6246 68.243 74.3229C68.0659 74.0165 67.9773 73.6645 67.9773 73.2671C67.9773 72.8696 68.0659 72.5201 68.243 72.2184C68.4202 71.912 68.6596 71.6725 68.9613 71.5001C69.2678 71.3278 69.6077 71.2416 69.9812 71.2416C70.3595 71.2416 70.6995 71.3278 71.0012 71.5001C71.3076 71.6725 71.5495 71.912 71.7266 72.2184C71.9086 72.5201 71.9996 72.8696 71.9996 73.2671C71.9996 73.6645 71.9086 74.0165 71.7266 74.3229C71.5495 74.6246 71.3076 74.8616 71.0012 75.034C70.6995 75.2064 70.3595 75.2926 69.9812 75.2926ZM71.6763 67.837C72.002 67.837 72.2773 67.7245 72.5023 67.4994C72.7322 67.2696 72.8471 66.9943 72.8471 66.6734C72.8471 66.3478 72.7322 66.0725 72.5023 65.8474C72.2773 65.6224 72.002 65.5099 71.6763 65.5099C71.3555 65.5099 71.0802 65.6224 70.8503 65.8474C70.6253 66.0725 70.5128 66.3478 70.5128 66.6734C70.5128 66.9943 70.6253 67.2696 70.8503 67.4994C71.0802 67.7245 71.3555 67.837 71.6763 67.837ZM68.2862 67.837C68.6118 67.837 68.8871 67.7245 69.1122 67.4994C69.342 67.2696 69.4569 66.9943 69.4569 66.6734C69.4569 66.3478 69.342 66.0725 69.1122 65.8474C68.8871 65.6224 68.6118 65.5099 68.2862 65.5099C67.9653 65.5099 67.69 65.6224 67.4601 65.8474C67.2351 66.0725 67.1226 66.3478 67.1226 66.6734C67.1226 66.9943 67.2351 67.2696 67.4601 67.4994C67.69 67.7245 67.9653 67.837 68.2862 67.837Z"
        fill={textColor}
      />
      <path
        d="M75.212 76.4446C75.212 76.7207 75.4358 76.9446 75.712 76.9446H76.9171C77.1932 76.9446 77.4171 76.7207 77.4171 76.4446V72.8361C77.4171 72.5632 77.4961 72.3094 77.6541 72.0748C77.8169 71.8353 78.0252 71.6414 78.279 71.493C78.5375 71.3445 78.8105 71.2703 79.0978 71.2703C79.3301 71.2703 79.555 71.3075 79.7725 71.382C80.0556 71.479 80.3922 71.3937 80.5248 71.1255L80.9946 70.1748C81.1084 69.9445 81.0291 69.6624 80.7889 69.5713C80.6959 69.5359 80.5967 69.5037 80.4912 69.4747C80.2183 69.398 79.9525 69.3597 79.6939 69.3597C79.239 69.3597 78.8129 69.4723 78.4154 69.6973C78.0228 69.9176 77.69 70.2336 77.4171 70.6454V70.0896C77.4171 69.8134 77.1932 69.5896 76.9171 69.5896H75.712C75.4358 69.5896 75.212 69.8134 75.212 70.0896V76.4446Z"
        fill={textColor}
      />
      <path
        d="M84.091 77.1744C85.1061 77.1744 85.8962 76.9589 86.4612 76.528C87.031 76.0922 87.3159 75.4745 87.3159 74.6749C87.3159 74.2391 87.2345 73.892 87.0717 73.6334C86.9089 73.37 86.6839 73.1569 86.3966 72.9941C86.1141 72.8313 85.7885 72.6829 85.4197 72.5488C85.1947 72.4674 84.9577 72.3788 84.7087 72.2831C84.4645 72.1825 84.2562 72.0676 84.0838 71.9383C83.9162 71.809 83.8324 71.6606 83.8324 71.493C83.8324 71.311 83.9138 71.1602 84.0766 71.0405C84.2394 70.9208 84.4573 70.8609 84.7302 70.8609C85.0887 70.8609 85.4853 70.9701 85.9201 71.1884C86.1807 71.3193 86.5066 71.2371 86.6438 70.9797L86.9001 70.4988C87.0332 70.2492 86.9333 69.9371 86.6725 69.8276C85.952 69.5253 85.2998 69.3741 84.7158 69.3741C84.1317 69.3741 83.6145 69.4723 83.1644 69.6686C82.7191 69.8649 82.3695 70.1378 82.1157 70.4874C81.862 70.8322 81.7351 71.232 81.7351 71.6869C81.7351 72.0652 81.8189 72.3764 81.9865 72.6206C82.1588 72.8601 82.3815 73.0588 82.6544 73.2168C82.9274 73.3748 83.2123 73.5161 83.5092 73.6406C84.0263 73.856 84.407 74.026 84.6512 74.1505C84.8954 74.2702 85.0175 74.4714 85.0175 74.7539C85.0175 75.0077 84.9313 75.204 84.7589 75.3428C84.5913 75.4769 84.3687 75.544 84.091 75.544C83.5801 75.544 83.0827 75.3547 82.5988 74.9763C82.3409 74.7745 81.9532 74.8095 81.7794 75.087L81.3915 75.7064C81.2754 75.8918 81.2905 76.1344 81.4514 76.2826C82.0967 76.8771 82.9766 77.1744 84.091 77.1744Z"
        fill={textColor}
      />
      <path
        d="M91.7549 77.1744C92.2577 77.1744 92.7269 77.0715 93.1627 76.8656C93.6032 76.6549 93.9911 76.358 94.3263 75.9749V76.9446H96.5313V69.5896H94.3263V70.4874C94.0342 70.1331 93.6631 69.8577 93.213 69.6614C92.7628 69.4603 92.2768 69.3597 91.7549 69.3597C91.0462 69.3597 90.4165 69.5321 89.8659 69.8769C89.32 70.2169 88.8914 70.6813 88.5802 71.2703C88.2689 71.8545 88.1133 72.5153 88.1133 73.2527C88.1133 74.0093 88.2761 74.6844 88.6017 75.2782C88.9273 75.8672 89.3631 76.3317 89.909 76.6716C90.4596 77.0068 91.0749 77.1744 91.7549 77.1744ZM92.4085 75.2997C91.8387 75.2997 91.367 75.1082 90.9935 74.7251C90.62 74.3421 90.4333 73.856 90.4333 73.2671C90.4333 72.6781 90.62 72.1921 90.9935 71.809C91.3718 71.4259 91.8435 71.2344 92.4085 71.2344C92.9783 71.2344 93.4476 71.4259 93.8163 71.809C94.1898 72.1873 94.3765 72.6733 94.3765 73.2671C94.3765 73.8608 94.1922 74.3493 93.8235 74.7323C93.4548 75.1106 92.9831 75.2997 92.4085 75.2997ZM94.6064 67.837C94.932 67.837 95.2073 67.7245 95.4324 67.4994C95.6622 67.2696 95.7772 66.9943 95.7772 66.6734C95.7772 66.3478 95.6622 66.0725 95.4324 65.8474C95.2073 65.6224 94.932 65.5099 94.6064 65.5099C94.2856 65.5099 94.0102 65.6224 93.7804 65.8474C93.5553 66.0725 93.4428 66.3478 93.4428 66.6734C93.4428 66.9943 93.5553 67.2696 93.7804 67.4994C94.0102 67.7245 94.2856 67.837 94.6064 67.837ZM91.2162 67.837C91.5418 67.837 91.8171 67.7245 92.0422 67.4994C92.272 67.2696 92.387 66.9943 92.387 66.6734C92.387 66.3478 92.272 66.0725 92.0422 65.8474C91.8171 65.6224 91.5418 65.5099 91.2162 65.5099C90.8954 65.5099 90.62 65.6224 90.3902 65.8474C90.1651 66.0725 90.0526 66.3478 90.0526 66.6734C90.0526 66.9943 90.1651 67.2696 90.3902 67.4994C90.62 67.7245 90.8954 67.837 91.2162 67.837Z"
        fill={textColor}
      />
      <path
        d="M98.0868 76.4446C98.0868 76.7207 98.3107 76.9446 98.5868 76.9446H99.7919C100.068 76.9446 100.292 76.7207 100.292 76.4446V74.0356L103.054 76.7981C103.148 76.8919 103.275 76.9446 103.408 76.9446H105.426C105.649 76.9446 105.76 76.6748 105.602 76.5175L102.267 73.1952L105.44 70.0162C105.597 69.8586 105.485 69.5896 105.263 69.5896H103.212C103.081 69.5896 102.954 69.6415 102.861 69.7341L100.292 72.2759V65.0905C100.292 64.8143 100.068 64.5905 99.7919 64.5905H98.5868C98.3107 64.5905 98.0868 64.8143 98.0868 65.0905V76.4446Z"
        fill={textColor}
      />
      <path
        d="M106.605 76.4446C106.605 76.7207 106.829 76.9446 107.105 76.9446H108.31C108.586 76.9446 108.81 76.7207 108.81 76.4446V72.8361C108.81 72.5632 108.889 72.3094 109.047 72.0748C109.21 71.8353 109.418 71.6414 109.672 71.493C109.931 71.3445 110.203 71.2703 110.491 71.2703C110.723 71.2703 110.948 71.3075 111.166 71.382C111.449 71.479 111.785 71.3937 111.918 71.1255L112.388 70.1748C112.501 69.9445 112.422 69.6624 112.182 69.5713C112.089 69.5359 111.99 69.5037 111.884 69.4747C111.611 69.398 111.346 69.3597 111.087 69.3597C110.632 69.3597 110.206 69.4723 109.808 69.6973C109.416 69.9176 109.083 70.2336 108.81 70.6454V70.0896C108.81 69.8134 108.586 69.5896 108.31 69.5896H107.105C106.829 69.5896 106.605 69.8134 106.605 70.0896V76.4446Z"
        fill={textColor}
      />
      <path
        d="M114.612 68.0597C114.971 68.0597 115.278 67.9304 115.532 67.6718C115.785 67.4132 115.912 67.1044 115.912 66.7453C115.912 66.3861 115.785 66.0797 115.532 65.8259C115.278 65.5721 114.971 65.4452 114.612 65.4452C114.248 65.4452 113.937 65.5721 113.678 65.8259C113.425 66.0797 113.298 66.3861 113.298 66.7453C113.298 67.1092 113.425 67.4204 113.678 67.679C113.937 67.9328 114.248 68.0597 114.612 68.0597ZM113.506 76.4446C113.506 76.7207 113.73 76.9446 114.006 76.9446H115.211C115.487 76.9446 115.711 76.7207 115.711 76.4446V70.0896C115.711 69.8134 115.487 69.5896 115.211 69.5896H114.006C113.73 69.5896 113.506 69.8134 113.506 70.0896V76.4446Z"
        fill={textColor}
      />
      <path
        d="M117.273 76.4446C117.273 76.7207 117.497 76.9446 117.773 76.9446H118.978C119.254 76.9446 119.478 76.7207 119.478 76.4446V72.5919C119.478 72.1657 119.619 71.809 119.902 71.5217C120.184 71.2296 120.569 71.0836 121.058 71.0836C121.537 71.0836 121.913 71.232 122.186 71.5289C122.463 71.821 122.602 72.2304 122.602 72.7571V76.4446C122.602 76.7207 122.826 76.9446 123.102 76.9446H124.307C124.583 76.9446 124.807 76.7207 124.807 76.4446V72.2902C124.807 71.7061 124.685 71.1961 124.441 70.7603C124.197 70.3198 123.859 69.9774 123.428 69.7332C123.002 69.4842 122.506 69.3597 121.941 69.3597C121.434 69.3597 120.967 69.4579 120.541 69.6542C120.115 69.8458 119.76 70.0971 119.478 70.4084V70.0896C119.478 69.8134 119.254 69.5896 118.978 69.5896H117.773C117.497 69.5896 117.273 69.8134 117.273 70.0896V76.4446Z"
        fill={textColor}
      />
      <path
        d="M129.764 80.895C130.736 80.895 131.538 80.7322 132.17 80.4066C132.807 80.0858 133.281 79.6428 133.592 79.0778C133.908 78.5128 134.066 77.8687 134.066 77.1457V70.0896C134.066 69.8134 133.842 69.5896 133.566 69.5896H132.361C132.085 69.5896 131.861 69.8134 131.861 70.0896V70.4874C131.569 70.1331 131.203 69.8577 130.762 69.6614C130.322 69.4603 129.843 69.3597 129.326 69.3597C128.617 69.3597 127.985 69.5297 127.429 69.8697C126.879 70.2097 126.443 70.6741 126.122 71.2631C125.806 71.8521 125.648 72.5201 125.648 73.2671C125.648 74.0284 125.811 74.7036 126.136 75.2926C126.467 75.8815 126.905 76.3436 127.451 76.6788C127.997 77.0092 128.603 77.1744 129.268 77.1744C129.78 77.1744 130.259 77.0691 130.705 76.8584C131.155 76.6477 131.54 76.3532 131.861 75.9749V77.0092C131.861 77.603 131.665 78.0818 131.272 78.4457C130.884 78.8097 130.379 78.9916 129.757 78.9916C129.297 78.9916 128.871 78.8911 128.478 78.6899C128.239 78.5673 128.035 78.4188 127.866 78.2445C127.685 78.0569 127.407 77.9664 127.173 78.0807L126.224 78.5434C125.983 78.6613 125.874 78.9518 126.015 79.1808C126.32 79.677 126.737 80.0665 127.264 80.3491C127.939 80.713 128.772 80.895 129.764 80.895ZM129.929 75.2997C129.354 75.2997 128.883 75.1106 128.514 74.7323C128.15 74.3493 127.968 73.8656 127.968 73.2814C127.968 72.6877 128.155 72.1993 128.528 71.8162C128.907 71.4283 129.378 71.2344 129.943 71.2344C130.508 71.2344 130.978 71.4283 131.351 71.8162C131.725 72.1993 131.911 72.6877 131.911 73.2814C131.911 73.8656 131.725 74.3493 131.351 74.7323C130.982 75.1106 130.508 75.2997 129.929 75.2997Z"
        fill={textColor}
      />
      <path
        d="M138.689 77.1744C139.191 77.1744 139.661 77.0715 140.096 76.8656C140.537 76.6549 140.925 76.358 141.26 75.9749V76.4446C141.26 76.7207 141.484 76.9446 141.76 76.9446H142.965C143.241 76.9446 143.465 76.7207 143.465 76.4446V70.0896C143.465 69.8134 143.241 69.5896 142.965 69.5896H141.76C141.484 69.5896 141.26 69.8134 141.26 70.0896V70.4874C140.968 70.1331 140.597 69.8577 140.147 69.6614C139.696 69.4603 139.21 69.3597 138.689 69.3597C137.98 69.3597 137.35 69.5321 136.8 69.8769C136.254 70.2169 135.825 70.6813 135.514 71.2703C135.203 71.8545 135.047 72.5153 135.047 73.2527C135.047 74.0093 135.21 74.6844 135.535 75.2782C135.861 75.8672 136.297 76.3317 136.843 76.6716C137.393 77.0068 138.009 77.1744 138.689 77.1744ZM139.342 75.2997C138.772 75.2997 138.301 75.1082 137.927 74.7251C137.554 74.3421 137.367 73.856 137.367 73.2671C137.367 72.6781 137.554 72.1921 137.927 71.809C138.305 71.4259 138.777 71.2344 139.342 71.2344C139.912 71.2344 140.381 71.4259 140.75 71.809C141.123 72.1873 141.31 72.6733 141.31 73.2671C141.31 73.8608 141.126 74.3493 140.757 74.7323C140.388 75.1106 139.917 75.2997 139.342 75.2997Z"
        fill={textColor}
      />
      <path
        d="M145.02 76.4446C145.02 76.7207 145.244 76.9446 145.52 76.9446H146.726C147.002 76.9446 147.226 76.7207 147.226 76.4446V72.8361C147.226 72.5632 147.305 72.3094 147.463 72.0748C147.625 71.8353 147.834 71.6414 148.087 71.493C148.346 71.3445 148.619 71.2703 148.906 71.2703C149.139 71.2703 149.364 71.3075 149.581 71.382C149.864 71.479 150.201 71.3937 150.333 71.1255L150.803 70.1748C150.917 69.9445 150.838 69.6624 150.597 69.5713C150.504 69.5359 150.405 69.5037 150.3 69.4747C150.027 69.398 149.761 69.3597 149.502 69.3597C149.048 69.3597 148.621 69.4723 148.224 69.6973C147.831 69.9176 147.498 70.2336 147.226 70.6454V70.0896C147.226 69.8134 147.002 69.5896 146.726 69.5896H145.52C145.244 69.5896 145.02 69.8134 145.02 70.0896V76.4446Z"
        fill={textColor}
      />
    </svg>
  );
};

EvoliTagline.propTypes = {
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  height: PropTypes.number,
};

EvoliTagline.defaultProps = {
  textColor: "#5A37EA",
  backgroundColor: "none",
  height: 14,
};

import React from "react";
import { useFormikContext, Field } from "formik";
import { Transition } from "@headlessui/react";
import { isEmail } from "../../../../utils/helperFunctions";

const EmailField = () => {
  const { values, errors, touched, isSubmitting } = useFormikContext();

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email är obligatoriskt";
    } else if (!isEmail(value)) {
      error = "Ogiltig email";
    }
    return error;
  }

  return (
    <div>
      <label
        htmlFor="email"
        className="block text-sm font-semibold text-deep-blue"
      >
        Email
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 text-ash"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
        </div>
        <Field
          disabled={isSubmitting}
          validate={validateEmail}
          type="text"
          name="email"
          id="email"
          autoComplete="off"
          className={`text-deep-blue placeholder-italic focus:ring-brand focus:border-brand block w-full pl-10 sm:text-sm border-gray-300 rounded-md ${
            false ?? false ? "border-blush" : ""
          }`}
          placeholder=""
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <Transition
            show={touched?.email === true && !!errors.email}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-blush"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </Transition>
          <Transition
            show={
              touched?.email === true &&
              errors.email === undefined &&
              values.email.length > 0
            }
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-green-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </Transition>
        </div>
      </div>
      <span className="block h-2 pt-1 text-xs italic text-red-400">
        <Transition
          show={(touched.email && !!errors.email) ?? false}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {errors.email}
        </Transition>
      </span>
    </div>
  );
};

export default EmailField;

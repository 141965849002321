export const isEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const isSwedishMobile = (mobile) => {
  const regex = /^([+]46|0046|0)s*(7[02369])\s*(\d{4})\s*(\d{3})$/;

  return regex.test(mobile);
};

export const isDanishhMobile = (mobile) => {
  const regex = /^(0045|\+45)?\ ?[2-9][0-9]\ ?[1-9][0-9]\ ?([0-9]\ ?){4}$/;
  return regex.test(mobile);
};

export const isNorwegianMobile = (mobile) => {
  const regex = /^(0047|\+47|47)?[2-9]\d{7}$/;
  return regex.test(mobile);
};

import React, { useContext } from "react";
import { useFormikContext } from "formik";
import { RadioGroup } from "@headlessui/react";
import { QuoteContext } from "../../../../context/quote";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PaymentMethodField() {
  const { values, setFieldValue, isSubmitting } = useFormikContext();
  const { quote } = useContext(QuoteContext);

  return (
    <RadioGroup
      disabled={isSubmitting}
      value={values.paymentMethod}
      onChange={(e) => setFieldValue("paymentMethod", e)}
    >
      <div className="relative -space-y-px bg-white rounded-md">
        <RadioGroup.Option
          name="paymentMethod"
          value="Monthly"
          className={({ checked }) =>
            classNames(
              checked
                ? "bg-indigo-50 border-indigo-200 z-10"
                : "border-gray-200",
              "rounded-tl-md rounded-tr-md relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none"
            )
          }
        >
          {({ active, checked }) => (
            <>
              <div className="flex items-center text-sm">
                <span
                  className={classNames(
                    checked
                      ? "bg-brand border-transparent"
                      : "bg-white border-gray-300",
                    active ? "ring-2 ring-offset-2 ring-brand-light" : "",
                    "h-4 w-4 rounded-full border flex items-center justify-center"
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <RadioGroup.Label
                  as="span"
                  className="ml-3 font-medium text-gray-900"
                >
                  Månad
                </RadioGroup.Label>
              </div>
              <RadioGroup.Description className="pl-1 ml-6 text-sm md:ml-0 md:pl-0 md:text-left">
                <span
                  className={classNames(
                    checked ? "text-brand" : "text-gray-900",
                    "font-medium"
                  )}
                >
                  {quote?.monthlyPremiumWithTax}:- i månaden
                </span>{" "}
              </RadioGroup.Description>

              <RadioGroup.Description
                className={classNames(
                  checked ? "text-dark-night" : "text-gray-500",
                  "ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right"
                )}
              >
                Kortbetalning
              </RadioGroup.Description>
            </>
          )}
        </RadioGroup.Option>

        <RadioGroup.Option
          name="paymentMethod"
          value="Annually"
          className={({ checked }) =>
            classNames(
              checked
                ? "bg-indigo-50 border-indigo-200 z-10"
                : "border-gray-200",
              "relative border p-4 flex flex-col rounded-bl-md rounded-br-md cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none"
            )
          }
        >
          {({ active, checked }) => (
            <>
              <div className="flex items-center text-sm">
                <span
                  className={classNames(
                    checked
                      ? "bg-brand border-transparent"
                      : "bg-white border-gray-300",
                    active ? "ring-2 ring-offset-2 ring-brand-light" : "",
                    "h-4 w-4 rounded-full border flex items-center justify-center"
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <RadioGroup.Label
                  as="span"
                  className="ml-3 font-medium text-gray-900"
                >
                  Helår
                </RadioGroup.Label>
              </div>
              <RadioGroup.Description className="pl-1 ml-6 text-sm md:ml-0 md:pl-0 md:text-left">
                <span
                  className={classNames(
                    checked ? "text-brand" : "text-gray-900",
                    "font-medium"
                  )}
                >
                  {quote?.annualPremiumWithTax}:- per år
                </span>{" "}
              </RadioGroup.Description>

              <RadioGroup.Description
                className={classNames(
                  checked ? "text-dark-night" : "text-gray-500",
                  "ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right"
                )}
              >
                Kortbetalning
              </RadioGroup.Description>
            </>
          )}
        </RadioGroup.Option>
      </div>
    </RadioGroup>
  );
}

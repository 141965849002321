import React from "react";
import { useFormikContext, Field } from "formik";
import { Transition } from "@headlessui/react";
import {
  isInteger,
  isSocialSecurityNumber,
} from "../../../../utils/helperFunctions";

const PriceField = () => {
  const { values, errors, touched, isSubmitting } = useFormikContext();

  function validatePriceRange(value) {
    let error;
    if (!value) {
      error = "Produktpris är obligatoriskt";
    } else if (!isInteger(value)) {
      error = "Ogiltigt format";
    } else if (parseInt(value) < 1) {
      error = "Beloppet är för lågt.";
    }

    return error;
  }

  return (
    <div>
      <label
        htmlFor="email"
        className="block text-sm font-semibold text-deep-blue"
      >
        Produktpris
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 text-ash"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <Field
          disabled={isSubmitting}
          validate={validatePriceRange}
          type="tel"
          name="priceRange"
          id="priceRange"
          autoComplete="off"
          className={`text-deep-blue placeholder-italic focus:ring-brand focus:border-brand block w-full pl-10 sm:text-sm border-gray-300 rounded-md ${
            false ?? false ? "border-blush" : ""
          }`}
          placeholder=""
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <Transition
            show={touched?.priceRange === true && !!errors.priceRange}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-blush"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </Transition>
          <Transition
            show={
              touched?.priceRange === true &&
              errors.priceRange === undefined &&
              values.priceRange.length > 0
            }
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-green-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </Transition>
        </div>
      </div>
      <span className="block h-2 pt-1 text-xs italic text-red-400">
        <Transition
          show={(touched.priceRange && !!errors.priceRange) ?? false}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {errors.priceRange}
        </Transition>
      </span>
    </div>
  );
};

export default PriceField;

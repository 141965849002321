import React, { useState, useMemo } from "react";
import { Form, Formik } from "formik";
import useProducts from "../../hooks/useProducts";
import Step1 from "../../components/Wizard/Step1/Step1";
import Helmet from "../../components/Helmet/Helmet";
import Step2 from "../../components/Wizard/Step1/Step2";
import useGetQuote from "../../hooks/useGetQuote";
import { QuoteContext } from "../../context/quote";
import usePurchaseQuote from "../../hooks/usePurchaseQuote";
import Step3 from "../../components/Wizard/Step3";
import ErrorModal from "../../components/Wizard/errorModal";
import ConfirmModal from "../../components/Wizard/confirmModal";

// Wizard is a single Formik instance whose children are each page of the
// multi-step form. The form is submitted on each forward transition (can only
// progress with valid input), whereas a backwards step is allowed with
// incomplete data. A snapshot of form state is used as initialValues after each
// transition. Each page has an optional submit handler, and the top-level
// submit is called when the final page is submitted.
const Wizard = ({ children, initialValues, onSubmit }) => {
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);
  const [confirm, setConfirm] = useState({
    isConfirmed: false,
    openConfirm: false,
  });

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const [error, setError] = useState({
    hasError: false,
    message: "",
    key: "",
  });

  const next = (values) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = (values) => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (values, bag) => {
    if (stepNumber === 1) {
      if (!confirm.isConfirmed) {
        setConfirm({
          isConfirmed: false,
          openConfirm: true,
        });
        return;
      }
    }

    if (step.props.onSubmit) {
      const response = await step.props.onSubmit(values, bag);

      if (response.error) {
        setError({
          hasError: true,
          title: "Det gick inte att skapa en offert",
          message: response.error.message,
          key: "",
        });

        return;
      }

      if (response.status.code !== 0) {
        setError({
          hasError: true,
          title: "Det gick inte att skapa en offert",
          message: response.status.message,
          key:
            response.result &&
            Object.keys(response.result)
              .filter((item) => item !== "type")
              .toString(),
        });

        return;
      }

      bag.setTouched({});
      next(values);

      return;
    }

    if (stepNumber === 1) {
      const response = await onSubmit(values, bag);

      if (response.error) {
        setError({
          hasError: true,
          title: "Det gick inte att teckna försäkringen",
          message:
            response.error.message ?? "Det gick inte att genomföra köpet.",
          key: "",
        });

        setConfirm({
          isConfirmed: false,
          openConfirm: false,
        });
        return;
      }

      if (response.status.code !== 0) {
        setError({
          hasError: true,
          title: "Det gick inte att teckna försäkringen",
          message:
            response.status.message ?? "Det gick inte att genomföra köpet.",
          key:
            response.result &&
            Object.keys(response.result)
              .filter((item) => item !== "type")
              .toString(),
        });

        setConfirm({
          isConfirmed: false,
          openConfirm: false,
        });
        return;
      }

      bag.setTouched({});
      next(values);
    }
  };

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors = {};

        if (!values.product) {
          errors.product = "Produkt är obligatoriskt";
        }

        return errors;
      }}
    >
      {(formik) => (
        <Form>
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="px-4 py-8 sm:px-0">
              <div className="space-y-6 bg-white shadow sm:rounded-md">
                <ErrorModal error={error} setError={setError} />

                <ConfirmModal
                  confirm={confirm}
                  setConfirm={setConfirm}
                  formik={formik}
                />

                {step}

                <StepNavigation
                  currentStep={stepNumber}
                  totalSteps={totalSteps}
                />
                <div className="px-4 py-3 text-right bg-gray-100 shadow sm:px-6 sm:rounded-md">
                  <div className="flex justify-end">
                    {isLastStep && (
                      <button
                        onClick={() => window.location.reload()}
                        type="button"
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Avsluta och börja om
                      </button>
                    )}
                    {!isLastStep && (
                      <>
                        {(formik.values.product ||
                          formik.values.socialSecurityNumber ||
                          formik.values.priceRange) && (
                          <button
                            onClick={() => window.location.reload()}
                            type="button"
                            className="px-4 py-2 text-sm font-medium text-gray-700 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Rensa och börja om
                          </button>
                        )}
                        {stepNumber > 0 && (
                          <button
                            onClick={() => previous(formik.values)}
                            type="button"
                            className="px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Bakåt
                          </button>
                        )}
                        <button
                          disabled={formik.isSubmitting}
                          type="submit"
                          className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          {formik.isSubmitting && (
                            <svg
                              className="w-5 h-5 mr-3 text-white animate-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          )}

                          {stepNumber === 0 && "Beräkna pris"}
                          {stepNumber === 1 && "Teckna försäkring"}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* {getEnv !== "prod" && purchase && (
              <div className="overflow-hidden bg-white shadow opacity-60 sm:rounded-lg">
                <div className="px-4 py-5 border-t border-gray-200 sm:px-6">
                  <div className="relative my-6">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-start">
                      <span className="pr-2 text-sm text-gray-500 bg-white">
                        Test environment data
                      </span>
                    </div>
                  </div>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        guid
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {purchase.guid}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        quoteId
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {purchase.quoteId}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        reminderUrl
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {purchase.reminderUrl}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Payment url
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {purchase.redirectUrl}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            )} */}
          </div>

          {/* <pre>{JSON.stringify(formik.values.selects, null, 2)}</pre> */}
        </Form>
      )}
    </Formik>
  );
};

const WizardStep = ({ children }) => children;

const QuoteWizard = () => {
  const { data } = useProducts();
  const [getQuote, quote] = useGetQuote();
  const [purchaseQuote, purchase] = usePurchaseQuote();
  const [dropdownState, setDropdownState] = useState([{}]);

  const value = useMemo(
    () => ({
      quote: quote?.data?.result,
      purchase: purchase?.data?.result,
      FoldersAndProducts: data,
      dropdownState,
      setDropdownState,
    }),
    [quote, dropdownState, setDropdownState]
  );

  if (!data) return null;

  return (
    <QuoteContext.Provider value={value}>
      <div>
        <Helmet title="Skapa offert | evoli product portal" />

        <Wizard
          initialValues={{
            product: null,
            resetSearch: null,
            socialSecurityNumber: "",
            priceRange: "",
            selectState: null,
            email: "",
            phone: "",
            paymentMethod: "Monthly",
            quote: null,
            searchItems: [],
            selects: [
              {
                categoryId: 1,
                parentId: 0,
                name: "Bläddra fram produkt",
                hasProducts: false,
                ...(data.hasProducts
                  ? {
                      products: data.products.map((item) => {
                        return {
                          categoryId: item.categoryId,
                          parentId: item.parentId,
                          name: item.name,
                          hasProducts: true,
                        };
                      }),
                    }
                  : {}),
                options: data.children.map((item) => {
                  return {
                    categoryId: item.categoryId,
                    parentId: item.parentId,
                    name: item.name,
                    hasProducts: false,
                  };
                }),
              },
            ],
          }}
          onSubmit={async (values) => {
            const response = await purchaseQuote({
              agreementId: 200,
              inputData: {
                category: values.product.classification.category,
                brand: values.product.classification.brand,
                priceRange: values.priceRange,
                itemName: values.product.name,
                socialSecurity: values.socialSecurityNumber,

                firstName: "",
                lastName: "",
                address: "",
                telephoneNumber: values.phone,
                email: values.email,
                paymentMethod: values.paymentMethod,
              },
            });

            return response;
          }}
          data={data}
        >
          <WizardStep
            onSubmit={async (values) => {
              const response = await getQuote({
                agreementId: 200,
                inputData: {
                  category: values.product.classification.category,
                  brand: values.product.classification.brand,
                  priceRange: values.priceRange,
                  socialSecurity: values.socialSecurityNumber,
                },
              });

              return response;
            }}
          >
            <Step1 data={data} />
          </WizardStep>

          <WizardStep>
            <Step2 data={data} />
          </WizardStep>

          <WizardStep onSubmit={() => console.log("Step2 onSubmit")}>
            <Step3 purchase={purchase.result} />
          </WizardStep>
        </Wizard>
        {/* <pre>{JSON.stringify(formik.values.selects, null, 2)}</pre> */}
      </div>
    </QuoteContext.Provider>
  );
};

export default QuoteWizard;

function StepNavigation({ currentStep, totalSteps }) {
  return (
    <nav className="flex items-center justify-center" aria-label="Progress">
      <ol className="flex items-center ml-8 space-x-5">
        {[...Array(parseInt(totalSteps))].map((e, index) => {
          return (
            <li key={index}>
              {/* complete */}
              {currentStep > index ? (
                <div
                  href="#"
                  className="block w-2.5 h-2.5 bg-indigo-600 rounded-full "
                >
                  <span className="sr-only">{index}</span>
                </div>
              ) : // current
              currentStep === index ? (
                <div
                  className="relative flex items-center justify-center"
                  aria-current="step"
                >
                  <span
                    className="absolute flex w-5 h-5 p-px"
                    aria-hidden="true"
                  >
                    <span className="w-full h-full bg-indigo-200 rounded-full" />
                  </span>
                  <span
                    className="relative block w-2.5 h-2.5 bg-indigo-600 rounded-full"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{index}</span>
                </div>
              ) : (
                <div className="block w-2.5 h-2.5 bg-gray-200 rounded-full"></div>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

import { Fragment, useContext, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { useFormikContext } from "formik";
import {
  FolderIcon,
  DocumentIcon,
  CheckIcon,
  SelectorIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { deepFind, findObjectByKeyVal } from "../../utils/helperFunctions";
import { QuoteContext } from "../../context/quote";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FieldArrayChild({
  folders,
  products,
  categoryId,
  productId,
  label,
  push,
  pop,
  replace,
  remove,
}) {
  const [selected, setSelected] = useState();
  const { values, setFieldValue, isSubmitting } = useFormikContext();
  const { FoldersAndProducts } = useContext(QuoteContext);

  var index = values.selects
    .map(function (e) {
      return e.categoryId;
    })
    .indexOf(categoryId);

  useEffect(() => {
    // If product is selected, find product object and set it as selected.
    if (values?.product?.categoryId === categoryId) {
      const currentData = findObjectByKeyVal(
        FoldersAndProducts,
        "productId",
        values.product.productId
      );

      setSelected(currentData);

      return;
    }

    // Find select and set as selected
    if (values.product) {
      if (values.selects.find((select) => select.categoryId === categoryId)) {
        if (index === 0) {
          const currentData = deepFind(
            FoldersAndProducts.children,
            function (obj) {
              return (
                obj.categoryId ===
                (values.selects.length === 1
                  ? values.selects[0].categoryId
                  : values.selects[1].categoryId)
              );
            }
          );

          setSelected(currentData);
          return;
        }

        const currentData = deepFind(
          FoldersAndProducts.children,
          function (obj) {
            return obj.parentId === categoryId;
          }
        );

        setSelected(currentData);
        return;
      }
    }
  }, []);

  useEffect(() => {
    if (values.product) {
      var productIndex = values.selects
        .map(function (e) {
          return e.categoryId;
        })
        .indexOf(values.product.categoryId);

      if (productIndex < index) {
        setSelected();
      }
    }

    if (!values.product && index !== 0 && selected) {
      var selectedIndex = values.selects
        .map(function (e) {
          return e.categoryId;
        })
        .indexOf(selected.categoryId);

      if (selectedIndex < index) {
        setSelected();
      }
    }
  }, [values.product, values.selects]);

  function handleChange(change) {
    const currentData = deepFind(FoldersAndProducts.children, function (obj) {
      return obj.categoryId === change.categoryId;
    });

    if (change.productId) {
      setFieldValue("resetSearch", true);
      setFieldValue("product", change);
      setSelected(change);

      var currentIndex = values.selects.findIndex(
        (x) => x.categoryId == change.categoryId
      );

      for (var i = values.selects.length - 1; i >= 0; i--) {
        if (i > currentIndex) {
          pop();
        }
      }

      return;
    }

    if (values.selects.find((item) => item.parentId === change.parentId)) {
      var currentIndex = values.selects.findIndex(
        (x) => x.parentId == change.parentId
      );

      // Replace and update selects values
      replace(currentIndex, {
        categoryId: change.categoryId,
        parentId: change.parentId,
        name: change.name,
        hasProducts: change.hasProducts,
        ...(currentData.hasProducts
          ? {
              products: currentData.products.map((item) => {
                return {
                  active: item.active,
                  classification: item.classification,
                  description: item.description,
                  name: item.name,
                  productId: item.productId,
                  categoryId: item.categoryId,
                };
              }),
            }
          : {}),
        options: currentData.children.map((item) => {
          return {
            categoryId: item.categoryId,
            parentId: item.parentId,
            name: item.name,
            hasProducts: false,
          };
        }),
      });

      //Remove current product due to change in folder
      setFieldValue("product", null);

      // Remove dropdowns that are after current one.
      for (var i = values.selects.length - 1; i >= 0; i--) {
        if (i > currentIndex) {
          pop();
        }
      }
    } else {
      push({
        categoryId: change.categoryId,
        parentId: change.parentId,
        name: change.name,
        hasProducts: change.hasProducts,
        ...(currentData?.hasProducts || currentData.productId
          ? {
              products: currentData.products.map((item) => {
                return {
                  active: item.active,
                  classification: item.classification,
                  description: item.description,
                  name: item.name,
                  productId: item.productId,
                  categoryId: item.categoryId,
                };
              }),
            }
          : {}),
        options: currentData?.children?.map((item) => {
          return {
            categoryId: item.categoryId,
            parentId: item.parentId,
            name: item.name,
            hasProducts: false,
          };
        }),
      });
    }

    setSelected(change);
  }

  return (
    <Listbox value={selected} onChange={handleChange} disabled={isSubmitting}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            {label}
          </Listbox.Label>
          <div className="relative max-w-sm mt-1">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-brand focus:border-brand sm:text-sm">
              {selected?.productId > 0 ? (
                <div className="flex items-center ">
                  <CheckCircleIcon
                    className="w-5 h-5 mr-3 text-green-500 "
                    aria-hidden="true"
                  />
                  <span className="block truncate">
                    {selected ? selected.name : "Välj"}
                  </span>
                </div>
              ) : (
                <span className="block truncate">
                  {selected ? selected.name : "Välj"}
                </span>
              )}

              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base rounded-md shadow-lg bg-gray-50 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {folders?.length === 0 &&
                  (!products || products?.length === 0) && (
                    <Listbox.Option
                      className={({ active }) =>
                        classNames(
                          active
                            ? "text-white bg-brand-light"
                            : "text-dark-night",
                          "cursor-default select-none relative py-2 pl-3 pr-9"
                        )
                      }
                      disabled={true}
                      value={null}
                    >
                      <div className="flex items-center">
                        <ExclamationIcon
                          className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-200"
                          aria-hidden="true"
                        />

                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "ml-3 block truncate italic"
                          )}
                        >
                          Inget valbart
                        </span>
                      </div>
                    </Listbox.Option>
                  )}

                {folders?.map((item) => (
                  <Listbox.Option
                    key={item.categoryId ?? item.productId}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "text-white bg-brand-light"
                          : "text-dark-night",
                        "cursor-default select-none rounded-sm relative py-2 pl-3 pr-9"
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {item.productId ? (
                            <DocumentIcon
                              className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-200"
                              aria-hidden="true"
                            />
                          ) : (
                            <FolderIcon
                              // className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-200"
                              className={classNames(
                                active ? "text-white" : "text-gray-400",
                                "w-5 h-5 mr-3"
                              )}
                              aria-hidden="true"
                            />
                          )}

                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",

                              active ? "text-mist" : "text-dark-night",
                              "ml-3 block truncate"
                            )}
                          >
                            {item.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-brand-light",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}

                {folders?.length > 0 && products && (
                  <div className="relative py-2">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-200" />
                    </div>
                  </div>
                )}

                {products?.map((item) => (
                  <Listbox.Option
                    key={item.productId}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <DocumentIcon
                            className={classNames(
                              active ? "text-white" : "text-gray-400",
                              "w-5 h-5 mr-3"
                            )}
                            aria-hidden="true"
                          />

                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {item.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

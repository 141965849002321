import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Helmet from "../../components/Helmet/Helmet";
import SignInForm from "../../components/LandingPage/SignInForm";
import QRCode from "qrcode.react";
import { CheckIcon } from "@heroicons/react/outline";
import { EvoliTagline } from "../../components/Logo/logo";

export default function Login() {
  return (
    <>
      <Helmet title="Evoli portal" />
      <div className="flex flex-col justify-center min-h-screen py-12 bg-gray-50 sm:px-6 lg:px-8 dark:bg-pitch-dark">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-6 bg-white shadow sm:rounded-lg sm:px-10 dark:bg-dark-night">
            <EvoliTagline />

            <div className="relative mt-6 mb-6 opacity-80">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center">
                <span className="px-3 text-sm font-medium text-gray-800 bg-white">
                  Sign in to your account
                </span>
              </div>
            </div>

            <SignInForm />
          </div>
        </div>
        {/* <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
          <p className="mt-2 text-sm text-center text-deep-blue text-opacity-60 dark:text-moonlight">
            No account? Contact our{" "}
            <a
              href="#"
              className="font-medium text-brand hover:text-brand-light dark:text-brand-light dark:hover:text-brand"
            >
              sales team
            </a>
          </p>
        </div> */}
      </div>
    </>
  );
}

export const QRmodal = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 500);
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                  <CheckIcon
                    className="w-6 h-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Försäkringen är tecknad.
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      <strong>Glöm inte</strong> att du måste ansluta ett
                      betalkort till din försäkring <strong>senast idag</strong>{" "}
                      för att den ska <strong>bli giltig</strong>.
                      <br />
                      <br />
                      Du kan göra det genom att scanna koden eller klicka på
                      länken som skickats via email.
                    </p>
                  </div>
                  <div className="flex justify-center my-8">
                    <QRCode value="https://www.youtube.com/watch?v=NUXPC6gr3HU" />
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Stäng
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

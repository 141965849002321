import React from "react";
import { useRouteMatch } from "react-router-dom";
import { AccountTab } from "../../components/UserComponents/AccountTab/AccountTab";
import { PasswordTab } from "../..//components/UserComponents/PasswordTab/PasswordTab";
import PasswordNavigation from "../../components/UserComponents/PasswordNavigation";
import Helmet from "../../components/Helmet/Helmet";

function AccountSettings() {
  const currentTab = useRouteMatch(["/account/password", "/account"])?.path;

  return (
    <>
      <Helmet title="Account settings | Evoli product portal" />
      <div className="overflow-hidden bg-white rounded-lg shadow">
        <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
          <PasswordNavigation currentTab={currentTab} />
          {currentTab === "/account" && <AccountTab />}
          {currentTab === "/account/password" && <PasswordTab />}
        </div>
      </div>
    </>
  );
}

export default AccountSettings;

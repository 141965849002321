import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { EvoliTagline } from "../Logo/logo";
import { UserMenu } from "../Header/UserMenu";
import { UserMenuSmall } from "../Header/UserMenuSmall";

export default function AppBar() {
  return (
    <Disclosure as="nav" className="border-b border-gray-300 bg-mist">
      {({ open }) => (
        <>
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex items-center flex-shrink-0">
                  <EvoliTagline height={11} />
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {/* Account dropdown */}
                <UserMenu />
              </div>
              <div className="flex items-center -mr-2 sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md bg-mist hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <UserMenuSmall />
        </>
      )}
    </Disclosure>
  );
}

// import React from "react";
// import { useLocation } from "react-router-dom";
// import { Toolbar } from "../Header/Header";
// import { Footer } from "./Footer";
// import { Header } from "./Header";

// function Layout({ children }) {
//   return (
//     <div className="flex flex-col h-screen">
//       <Toolbar />

//       <main className="flex-grow -mt-32">
//         <div className="px-4 pb-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
//           {children}
//         </div>
//       </main>
//       <Footer />
//     </div>
//   );
// }

// export default Layout;

// import { createContext, useContext } from "react";

// export const QuoteContext = createContext();

// export function useQuote() {
//   return useContext(QuoteContext);
// }

import { createContext } from "react";

export const QuoteContext = createContext();

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PencilAltIcon, CheckIcon } from "@heroicons/react/outline";
import { QuoteContext } from "../../context/quote";
import { useFormikContext } from "formik";

export default function ConfirmModal({ confirm, setConfirm, formik }) {
  return (
    <Transition.Root show={confirm.openConfirm} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={null}
        open={confirm.openConfirm}
        onClose={setConfirm}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                  <PencilAltIcon
                    className="w-6 h-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Teckna försäkringen?
                  </Dialog.Title>
                  <div className="flex justify-center max-w-full mt-2">
                    <p className="max-w-sm text-sm text-gray-500">
                      Kontrollera att uppgifterna stämmer och bekräfta att
                      försäkring ska tecknas på Teckna försäkring-knappen.
                    </p>
                  </div>
                </div>
                <QuoteSection />
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="submit"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  // className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"

                  onClick={() => {
                    setConfirm({
                      isConfirmed: true,
                      openConfirm: false,
                    });
                    formik.submitForm();
                  }}
                >
                  <CheckIcon
                    className="w-5 h-5 mr-2 -ml-1"
                    aria-hidden="true"
                  />
                  Teckna försäkringen
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() =>
                    setConfirm({
                      isConfirmed: false,
                      openConfirm: false,
                    })
                  }
                >
                  Avbryt
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const QuoteSection = () => {
  const { values } = useFormikContext();
  const { quote } = useContext(QuoteContext);

  const price = () => {
    if (values.paymentMethod === "Monthly") {
      return `${quote.monthlyPremiumWithTax}:- i månaden.`;
    }

    return `${quote.annualPremiumWithTax}:- per år.`;
  };

  return (
    <div className="flex justify-center px-4 py-5 sm:px-6">
      <dl className="grid grid-cols-2 px-4 py-5 border border-gray-200 rounded-lg sm:px-6 gap-x-14 gap-y-6 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-xs font-medium text-gray-500">Produkt</dt>
          <dd className="mt-1 text-xs text-gray-900">
            {quote.brand}
            <br />
            {values.product.name}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-xs font-medium text-gray-500">Betalning</dt>
          <dd className="mt-1 text-xs text-gray-900">{price()}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-xs font-medium text-gray-500">Produktpris</dt>
          <dd className="mt-1 text-xs text-gray-900">{values.priceRange}:-</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-xs font-medium text-gray-500">Personnummer</dt>
          <dd className="mt-1 text-xs text-gray-900">
            {values.socialSecurityNumber}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-xs font-medium text-gray-500">Email</dt>
          <dd className="mt-1 text-xs text-gray-900">{values.email}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-xs font-medium text-gray-500">Mobilnummer</dt>
          <dd className="mt-1 text-xs text-gray-900">{values.phone}</dd>
        </div>
      </dl>
    </div>
  );
};

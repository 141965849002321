import React from "react";
import { useFormik } from "formik";
import { Transition } from "@headlessui/react";
import { NewPasswordField, OldPasswordField } from "./Fields";

export const PasswordTab = () => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.oldPassword) {
        errors.oldPassword = "Current password field is required";
      }

      if (!values.newPassword) {
        errors.newPassword = "New password field is required";
      }
      // else if (!isSwedishMobile(values.mobile)) {
      //   errors.mobile = "Invalid mobile format";
      // }

      return errors;
    },
    onSubmit: (values) => {
      console.log("put to api");
    },
  });

  return (
    <form
      className="divide-y divide-gray-200 lg:col-span-9"
      onSubmit={formik.handleSubmit}
    >
      <Transition
        show={true}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="px-4 py-6 sm:p-6 lg:pb-8">
          <div>
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              Password
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Update and configure your password.
            </p>
          </div>

          <div className="grid grid-cols-12 gap-6 mt-6">
            <OldPasswordField formik={formik} />
            <NewPasswordField formik={formik} />
          </div>
          <div className="flex justify-end px-4 py-4 mt-4 sm:px-6">
            <button
              type="button"
              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex justify-center px-4 py-2 ml-5 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-light-blue-700 hover:bg-light-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
            >
              Save
            </button>
          </div>
        </div>
      </Transition>
    </form>
  );
};

import axios from "axios";
import { useMutation } from "react-query";
import { useAuth } from "../context/auth";

export default function usePurchaseQuote() {
  const { user } = useAuth();

  return useMutation(
    (values) =>
      axios
        .post(
          `${process.env.REACT_APP_AKKA_ENDPOINT}evoli/quote/purchase`,
          values,
          {
            headers: {
              credentials: "include",
              apiKey: user.apiKey,
            },
          }
        )
        .then((res) => res.data)
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);

            return { error: { hasError: true, message: error.message } };
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request);
            return { error: { hasError: true, message: error.request } };
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error);
            return { error: { hasError: true, message: error.message } };
          }
        }),
    {
      onError: (error) => {
        return error;
      },
      onSuccess: (data) => {
        return data;
      },
    }
  );
}

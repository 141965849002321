export const getEnv = () => {
  if (window.location.host.includes("localhost")) {
    return "dev";
  }
  if (window.location.host.includes("test")) {
    return "test";
  }

  return "prod";
};

export const isSocialSecurityNumber = (string) => {
  const re =
    /^(19|20)?[0-9]{2}[- ]?((0[0-9])|(10|11|12))[- ]?(([0-2][0-9])|(3[0-1])|(([7-8][0-9])|(6[1-9])|(9[0-1])))[- ]?[0-9]{4}$/;
  return re.test(string);
};

export const isSwedishMobile = (string) => {
  const re = /^([+]46|0046|0)s*(7[02369])\s*(\d{4})\s*(\d{3})$/;
  return re.test(string);
};

export const isEmail = (string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(string).toLowerCase());
};

export const isInteger = (string) => {
  const re = /^\d+$/;
  return re.test(String(string).toLowerCase());
};

export const recursiveSearch = (obj, searchKey, results = []) => {
  const r = results;
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (key === searchKey && typeof value === "object") {
      r.push(value);
    } else if (typeof value === "object") {
      recursiveSearch(value, searchKey, r);
    }
  });
  return r;
};

export function deepFind(arr, search) {
  for (var obj of arr) {
    if (search(obj)) {
      return obj;
    }
    if (obj.children) {
      var deepResult = deepFind(obj.children, search);
      if (deepResult) {
        return deepResult;
      }
    }
  }
  return null;
}

export const findObjectByKeyVal = function (obj, key, val) {
  if (!obj || typeof obj === "string") {
    return null;
  }
  if (obj[key] === val) {
    return obj;
  }

  for (var i in obj) {
    if (obj.hasOwnProperty(i)) {
      var found = findObjectByKeyVal(obj[i], key, val);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

export function AddSpacesToInteger(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

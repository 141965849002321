import React, { Fragment, useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import SearchField from "../../components/FreePeriodForm/searchField";
import PriceField from "../../components/FreePeriodForm/priceField";
import { getEnv, isInteger } from "../../utils/helperFunctions";
import PhoneField from "../../components/FreePeriodForm/phoneField";
import {
  isEmail,
  isDanishhMobile,
  isNorwegianMobile,
  isSwedishMobile,
} from "../../utils/validations";
import EmailField from "../../components/FreePeriodForm/emailField";
import { useAuth } from "../../context/auth";
import { ExclamationIcon } from "@heroicons/react/solid";
import { CheckIcon, CheckCircleIcon } from "@heroicons/react/outline";

const FreePeriodQuote = () => {
  const { user } = useAuth();
  const [products, setProducts] = useState(null);
  const [offer, setOffer] = useState(null);
  const [offerError, setOfferError] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_AKKA_ENDPOINT}portal/user/product/search/*`,
          {
            headers: {
              Authorization: "Bearer " + user.token,
              credentials: "include",
            },
          }
        )
        .then((e) => {
          if (e.data.length > 0) {
            setProducts(
              e.data.sort((a, b) => {
                let fa = a.name.toLowerCase(),
                  fb = b.name.toLowerCase();

                if (fa < fb) {
                  return -1;
                }
                if (fa > fb) {
                  return 1;
                }
                return 0;
              })
            );
          } else {
            setError("Inga produkter hittades till denna användare.");
          }
        })
        .catch((err) => {
          setError(err.message);
        });
    };

    fetchData();
  }, [user.token]);

  const formik = useFormik({
    initialValues: {
      selectedProduct: null,
      category: "",
      brand: "",
      itemName: "",
      telephoneNumber: "",
      email: "",
      priceRange: "",
      nrOfItems: 1,
    },
    // validateOnBlur: true,
    // validateOnChange: false,
    validate: (values) => {
      const errors = {};

      if (!values.priceRange) {
        errors.priceRange = "Produktpris är obligatoriskt";
      } else if (!isInteger(values.priceRange)) {
        errors.priceRange = "Ogiltigt format";
      } else if (parseInt(values.priceRange) < 1) {
        errors.priceRange = "Beloppet är för lågt.";
      }

      if (!values.telephoneNumber) {
        errors.telephoneNumber = "Mobilnummer är obligatoriskt";
      } else if (
        !isSwedishMobile(values.telephoneNumber) &&
        !isNorwegianMobile(values.telephoneNumber) &&
        !isDanishhMobile(values.telephoneNumber)
      ) {
        errors.telephoneNumber = "Ogiltigt nummer";
      }

      // if (formik.touched.email && values.email.length === 0) {
      //   formik.setFieldTouched("email", false);
      // }
      if (values.email.length > 0 && !isEmail(values.email)) {
        errors.email = "Felaktigt format på e-postadress";
      }

      return errors;
    },

    onSubmit: async (values) => {
      if (values.email.length > 0) {
        if (!isEmail(values.email)) {
          formik.setFieldError("email", "Felaktigt format på e-postadress");
          return;
        }
      }

      setOfferError(null);
      await axios
        .post(
          `${process.env.REACT_APP_AKKA_ENDPOINT}pending/offer`,
          {
            inputData: {
              telephoneNumber: values.telephoneNumber,
              email: values.email,
              category: values.selectedProduct.classification.category,
              brand: values.selectedProduct.classification.brand,
              itemName: values.selectedProduct.name,
              priceRange: values.priceRange,
              nrOfItems: values.nrOfItems,
            },
          },
          {
            headers: {
              apiKey: user.apiKey,
              credentials: "include",
            },
          }
        )
        .then((e) => {
          if (e.data && e.data.status.code === 0) {
            setOffer(e.data.result);
          } else {
            setOfferError(e.data.status.message ?? "Något gick fel");
          }
        })
        .catch((err) => {
          console.log(err);
          setOfferError(err.message);
        });
    },
  });

  if (error)
    return (
      <div className="max-w-lg mx-auto sm:px-6 lg:px-8">
        <div className="px-4 py-8 sm:px-0">
          <div className="space-y-6 bg-white shadow sm:rounded-md">
            <div className="col-span-6 p-4 sm:col-span-3">
              <div className="block mb-4 text-lg font-medium text-gray-700">
                Skapa erbjudande
              </div>

              <div className="p-4 border-l-4 border-yellow-400 bg-yellow-50">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon
                      className="w-5 h-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      <i>{error}</i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="max-w-lg mx-auto sm:px-6 lg:px-8">
      <div className="px-6 py-8 sm:px-0">
        <div className="space-y-6 bg-white shadow sm:rounded-md">
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div className="col-span-6 p-4 sm:col-span-3">
              <div className="block mb-4 text-lg font-medium text-gray-700">
                Skapa erbjudande
              </div>

              <SearchField
                setFieldValue={formik.setFieldValue}
                products={products}
                offer={offer}
              />

              <Transition
                show={formik.values.selectedProduct !== null}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <PriceField formik={formik} />
                <PhoneField formik={formik} />
                <EmailField formik={formik} />

                <div className="mt-4">
                  <button
                    type="submit"
                    className={`w-full flex justify-center py-2 px-4  border border-transparent rounded-md shadow-sm text-sm font-medium ${
                      !(formik.isValid && formik.dirty && offer === null) ||
                      formik.isSubmitting
                        ? "opacity-20 cursor-not-allowed"
                        : ""
                    } text-white bg-brand hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand`}
                    disabled={
                      !(formik.isValid && formik.dirty && offer === null) ||
                      formik.isSubmitting
                    }
                  >
                    {!formik.isSubmitting ? (
                      "Skicka länk till kund"
                    ) : (
                      <svg
                        className="w-5 h-5 mr-3 text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                  </button>

                  {offerError && (
                    <div className="p-4 mt-4 border-l-4 border-yellow-400 bg-yellow-50">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon
                            className="w-5 h-5 text-yellow-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-yellow-700">
                            <i>{offerError}</i>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Transition>
            </div>
          </form>

          <Transition.Root show={offer ? true : false} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-10 overflow-y-auto"
              onClose={() => {
                formik.resetForm();
                setOffer(null);
              }}
            >
              <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                    <div>
                      <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                        <CheckIcon
                          className="w-6 h-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Erbjudande skapat
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Länk med ett erbjudande om försäkring är nu skickat
                            via sms till kunden.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                        onClick={() => {
                          formik.resetForm();
                          setOffer(null);
                        }}
                      >
                        Skapa ett nytt erbjudande
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
      {/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
    </div>
  );
};
export default FreePeriodQuote;

import React, { useContext, useEffect, useState } from "react";
import SelectMenu from "../../SelectMenu/selectMenu";
import { useFormikContext, ErrorMessage, Field } from "formik";
import DynamicSelectMenu from "../../SelectMenu/DynamicSelectMenu";
import DynamicSelects from "../../SelectMenu/DynamicSelects";
import { Transition, RadioGroup } from "@headlessui/react";
import SocialSecurityField from "./Fields/SocialSecurityField";
import PriceField from "./Fields/PriceField";
import { BadgeCheckIcon } from "@heroicons/react/solid";
import EmailField from "./Fields/EmailField";
import PhoneField from "./Fields/PhoneField";
import PaymentMethodField from "./Fields/PaymentMethodField";
import { AddSpacesToInteger } from "../../../utils/helperFunctions";
import { QuoteContext } from "../../../context/quote";

function Step2({ data }) {
  const { values } = useFormikContext();
  const { quote } = useContext(QuoteContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!data) return null;

  return (
    <div className="px-4 py-5 md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <h3 className="text-base font-medium leading-6 text-gray-800">
          Bekräfta offert
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Nedan framgår vad försäkringen omfattar och pris presenteras till
          höger.
          <br /> <br />
          Om kund önskar teckna försäkringen behöver du välja betalsätt, ange
          email samt mobilnummer till kund och klicka på Teckna
          försäkring-knappen.
          <br />
          <br />
          Ni hittar mer information om försäkringen i{" "}
          <a
            href="https://evoli.se/forkopsinformation/Forkopsinformation-Produktforsakring.pdf"
            target="_blank"
            className="font-medium text-brand hover:text-brand-light"
          >
            Förköpsinformation
          </a>{" "}
          och{" "}
          <a
            href="https://evoli.se/produktfaktablad/IPID_produkt_2021-05-01.pdf"
            target="_blank"
            className="font-medium text-brand hover:text-brand-light"
          >
            Produktfaktablad
          </a>
          .
        </p>

        <div className="mt-6 sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            Försäkringen omfattar
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            <ul className="rounded-md ">
              {Object.entries(quote.coverMap).map((cover) => {
                return (
                  <li
                    key={cover[1].extId}
                    className="flex items-center justify-between py-2 pl-0 pr-4 text-sm"
                  >
                    <div className="flex items-center flex-1 w-0">
                      <BadgeCheckIcon
                        className="flex-shrink-0 w-5 h-5 text-green-500"
                        aria-hidden="true"
                      />
                      <span className="flex-1 w-0 ml-2 truncate">
                        {cover[1].coverageCaption}
                      </span>
                    </div>
                  </li>
                );
              })}
            </ul>
          </dd>
        </div>
      </div>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <div className="space-y-8">
          <div className="">
            <PaymentMethodField />
          </div>
          <div className="grid grid-cols-6 gap-6 mb-6">
            <div className="col-span-6 sm:col-span-3">
              <EmailField />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <PhoneField />
            </div>
          </div>

          <div>
            <div className="relative mb-6">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center"></div>
            </div>

            <div className="grid grid-cols-6 gap-6 gap-y-4 ">
              <div className="col-span-6 sm:col-span-3">
                <dt className="text-sm font-medium text-gray-500">Produkt</dt>
                <dd className="col-span-2 mt-1 text-sm text-gray-900 sm:mt-0">
                  {quote.brand} {values.product.name}
                </dd>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <dt className="text-sm font-medium text-gray-500">
                  Personnummer
                </dt>
                <dd className="col-span-2 mt-1 text-sm text-gray-900 sm:mt-0">
                  {values.socialSecurityNumber}
                </dd>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <dt className="text-sm font-medium text-gray-500">
                  Produktpris
                </dt>
                <dd className="col-span-2 mt-1 text-sm text-gray-900 sm:mt-0">
                  {AddSpacesToInteger(values.priceRange)}:-
                </dd>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step2;

function Breadcrumbs({ data }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {/* <li>
          <div>
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 w-5 h-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li> */}
        {data.selectState.map((page, index) => (
          <li key={page.key ?? page.name}>
            <div className="flex items-center">
              {index !== 0 && (
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
              )}
              <a
                href="#"
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {page.key ?? page.name}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

function Summary() {
  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Product</dt>
        <dd className="mt-1 text-sm text-gray-900">123</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Product pris</dt>
        <dd className="mt-1 text-sm text-gray-900">6000</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Personnummer</dt>
        <dd className="mt-1 text-sm text-gray-900">12121212</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Startdatum</dt>
        <dd className="mt-1 text-sm text-gray-900">2020-05-05</dd>
      </div>
      {/* <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          Salary expectation
        </dt>
        <dd className="mt-1 text-sm text-gray-900">$120,000</dd>
      </div> */}
      {/* <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">About</dt>
        <dd className="mt-1 text-sm text-gray-900">
          Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt
          cillum culpa consequat. Excepteur qui ipsum aliquip consequat sint.
          Sit id mollit nulla mollit nostrud in ea officia proident. Irure
          nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
        </dd>
      </div> */}
    </dl>
  );
}

import React, { useState } from "react";
import SelectMenu from "../../SelectMenu/selectMenu";
import { useFormikContext, ErrorMessage, Field } from "formik";
import DynamicSelectMenu from "../../SelectMenu/DynamicSelectMenu";
import DynamicSelects from "../../SelectMenu/DynamicSelects";
import { Transition } from "@headlessui/react";
import SocialSecurityField from "./Fields/SocialSecurityField";
import PriceField from "./Fields/PriceField";
// import { AddSpacesToInteger } from "../../../utils/helperFunctions";

import { MyFieldArray } from "../../SelectMenu/FieldArray";
import SearchProduct from "./Fields/SearchProductField";

function Step1({ data }) {
  const { values, errors, touched } = useFormikContext();

  if (!data) return null;

  return (
    <div className="px-4 py-5 md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <h3 className="text-base font-medium leading-6 text-gray-900">
          Skapa offert
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          För att beräkna pris på produktförsäkring, börja med att bläddra fram
          den aktuella produkten. Du behöver också fylla i kundens personnummer
          och produktens inköpspris. Klicka sedan på Beräkna pris-knappen.
          <br />
          <br />
          Du kan läsa mer om försäkringen här samt i{" "}
          <a
            href="https://evoli.se/forkopsinformation/Forkopsinformation-Produktforsakring.pdf"
            target="_blank"
            className="font-medium text-brand hover:text-brand-light"
          >
            Förköpsinformation
          </a>{" "}
          och{" "}
          <a
            href="https://evoli.se/produktfaktablad/IPID_produkt_2021-05-01.pdf"
            target="_blank"
            className="font-medium text-brand hover:text-brand-light"
          >
            Produktfaktablad
          </a>
          .
        </p>

        {/* <dl className="mt-2">
          {values.product?.name && (
            <div className="py-2">
              <dt className="text-sm font-medium text-gray-500">Produkt</dt>
              <dd className="col-span-2 mt-1 text-sm text-gray-900 sm:mt-0">
                {values.product.name}
              </dd>
            </div>
          )}
        </dl> */}

        {/* <pre> {JSON.stringify(values.product, null, 2)}</pre> */}
      </div>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <div className="space-y-8 divide-y divide-gray-200">
          <div>
            <div className="grid grid-cols-6 gap-6"></div>

            <div className="grid grid-cols-6 gap-6">
              <SearchProduct />
              <MyFieldArray />
            </div>

            <Transition
              show={(touched.product && !!errors.product) ?? false}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <span className="block h-2 pt-1 mt-4 text-xs italic text-red-400">
                {errors.product}
              </span>
            </Transition>
          </div>
          <div className="grid grid-cols-6 gap-6 pt-6">
            <div className="col-span-6 sm:col-span-3">
              <SocialSecurityField />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <PriceField />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step1;

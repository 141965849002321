import React, { useContext } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
  useFormikContext,
} from "formik";
import FieldArrayChild from "./FieldArrayChild";
import { QuoteContext } from "../../context/quote";

export const MyFieldArray = () => {
  const { values } = useFormikContext();
  const { FoldersAndProducts } = useContext(QuoteContext);

  return (
    <FieldArray name="selects">
      {({ remove, replace, push, pop }) => (
        <>
          {values.selects.length > 0 &&
            values.selects.map((select, index) => {
              //   console.log(select);
              return (
                <div className="col-span-6 sm:col-span-3" key={index}>
                  <FieldArrayChild
                    label={select.name}
                    select={select}
                    folders={select.options?.sort((a, b) =>
                      a.name.localeCompare(b.name)
                    )}
                    products={select.products?.sort((a, b) =>
                      a.name.localeCompare(b.name)
                    )}
                    categoryId={select.categoryId}
                    productId={select.productId}
                    push={push}
                    pop={pop}
                    replace={replace}
                    remove={remove}
                    index={index}
                  />
                </div>
              );
            })}
        </>
      )}
    </FieldArray>
  );
};

import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { useCombobox } from "downshift";
import { SearchIcon, CheckCircleIcon } from "@heroicons/react/solid";
import { DocumentIcon, XIcon } from "@heroicons/react/outline";

const SearchField = ({ setFieldValue, products, offer }) => {
  const [inputItems, setInputItems] = useState([]);

  useEffect(() => {
    if (!offer) {
      reset();
    }
  }, [offer]);

  const {
    isOpen,
    selectedItem,
    getMenuProps,
    reset,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    itemToString: (item) => {
      if (item) {
        return `${item?.name} ${item?.classification?.brand ?? ""}`;
      }
      return "";
    },
    onSelectedItemChange: (changes) => {
      setFieldValue("selectedProduct", changes.selectedItem);
    },

    onInputValueChange: ({ inputValue }) => {
      setInputItems(
        products.filter((item) =>
          item.name.toLowerCase().startsWith(inputValue.toLowerCase())
        )
      );
    },
  });

  return (
    <div>
      <div className="block text-sm font-medium text-gray-700">Sök produkt</div>
      <div
        className="relative mt-1 rounded-md shadow-sm"
        {...getComboboxProps()}
      >
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          {selectedItem ? (
            <CheckCircleIcon
              className="w-5 h-5 mr-3 text-green-500 "
              aria-hidden="true"
            />
          ) : products ? (
            <SearchIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
          ) : (
            <svg
              className="w-5 h-5 mr-3 text-gray-500 animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
        </div>

        <input
          {...getInputProps()}
          type="text"
          name="search"
          id="search"
          className="block w-full pl-10 border-gray-300 rounded-md focus:ring-brand focus:border-brand sm:text-sm"
          disabled={!products}
          placeholder={!products ? "Laddar produkter" : "Produktnamn"}
        />

        <Transition
          show={selectedItem ? true : false}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-0"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 ">
            <XIcon
              onClick={() => {
                reset();
                // setFieldValue("product", null);
              }}
              className="w-5 h-5 text-gray-400 cursor-pointer hover:text-gray-600"
              aria-hidden="true"
            />
          </div>
        </Transition>
      </div>

      <div className="relative max-w-sm ">
        <ul {...getMenuProps()}>
          <Transition
            show={isOpen}
            as={"div"}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className={classNames(
              inputItems.length > 0 ? "" : "hidden",
              "absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-gray-50 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            )}
          >
            {inputItems &&
              inputItems.map((item, index) => {
                const active = highlightedIndex === index;

                return (
                  <li
                    className={classNames(
                      active ? "text-white bg-brand-light" : "text-gray-900",
                      "cursor-pointer select-none relative py-2 pl-3 pr-9"
                    )}
                    key={`${item.category}${item.name}${index}`}
                    // {...getItemProps({ item, index })}
                    {...getItemProps({
                      item,
                      index,
                    })}
                  >
                    <div className="flex">
                      <DocumentIcon
                        className={classNames(
                          active ? "text-white" : "text-gray-400",
                          "w-5 h-5 mr-3"
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">{item.name}</span>
                      <span
                        className={classNames(
                          highlightedIndex === index
                            ? "text-indigo-200"
                            : "text-gray-500",
                          "ml-2 truncate"
                        )}
                      >
                        {item.category}
                      </span>
                    </div>
                  </li>
                );
              })}
          </Transition>
        </ul>
      </div>
    </div>
  );
};
export default SearchField;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

import React, { useState, Fragment, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { useCombobox } from "downshift";
import axios from "axios";
import { useAuth } from "../../../../context/auth";
import { SearchIcon, CheckCircleIcon } from "@heroicons/react/solid";
import { DocumentIcon, XIcon } from "@heroicons/react/outline";
import { useFormikContext } from "formik";

const SearchProduct = () => {
  const { values, setFieldValue } = useFormikContext();
  const [inputItems, setInputItems] = useState(values.searchItems);
  const { user } = useAuth();

  useEffect(() => {
    if (values.resetSearch) {
      setFieldValue("resetSearch", null);
      reset();
    }
  }, [values.resetSearch]);

  const {
    isOpen,
    selectedItem,
    getMenuProps,
    reset,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    selectItem,
    closeMenu,
    getItemProps,
  } = useCombobox({
    initialSelectedItem:
      values.selects.length > 1 ? null : values.product ?? null,
    items: inputItems,
    itemToString: (item) => {
      if (item) {
        return `${item?.name} ${item?.classification?.brand ?? ""}`;
      }
      return "";
    },
    onSelectedItemChange: (changes) => {
      if (changes.selectedItem !== null) {
        setFieldValue("product", changes.selectedItem);
        const resetSelects = values.selects[0];
        setFieldValue("selects", [resetSelects]);
      }
    },

    onInputValueChange: async (stateAndHelpers) => {
      const { inputValue, type } = stateAndHelpers;
      if (type === "__input_change__" || type === 6) {
        if (inputValue.length === 0) {
          selectItem(null);
          closeMenu();
          return;
        }

        if (typeof inputValue === "string") {
          await axios
            .get(
              `${process.env.REACT_APP_AKKA_ENDPOINT}portal/user/product/cat-search/${inputValue}`,
              {
                headers: {
                  Authorization: "Bearer " + user.token,
                  credentials: "include",
                },
              }
            )
            .then((res) => {
              setInputItems(
                res.data.sort((a, b) => a.name.localeCompare(b.name))
              );
            })
            .catch(function (error) {
              console.log("User has not permissions to search");
            });
        }
      }
    },
  });

  return (
    <div className="col-span-6 sm:col-span-3">
      <div className="block text-sm font-medium text-gray-700">Sök produkt</div>
      <div
        className="relative mt-1 rounded-md shadow-sm"
        {...getComboboxProps()}
      >
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          {selectedItem ? (
            <CheckCircleIcon
              className="w-5 h-5 mr-3 text-green-500 "
              aria-hidden="true"
            />
          ) : (
            <SearchIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
          )}
        </div>

        <input
          {...getInputProps()}
          type="text"
          name="search"
          id="search"
          className="block w-full pl-10 border-gray-300 rounded-md focus:ring-brand focus:border-brand sm:text-sm"
          placeholder="Produktnamn"
        />

        <Transition
          show={selectedItem ? true : false}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-0"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 ">
            <XIcon
              onClick={() => {
                reset();
                setFieldValue("product", null);
              }}
              className="w-5 h-5 text-gray-400 cursor-pointer hover:text-gray-600"
              aria-hidden="true"
            />
          </div>
        </Transition>
      </div>

      <div className="relative max-w-sm ">
        <ul {...getMenuProps()}>
          <Transition
            show={isOpen}
            as={"div"}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className={classNames(
              inputItems.length > 0 ? "" : "hidden",
              "absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-gray-50 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            )}
          >
            {inputItems &&
              inputItems.map((item, index) => {
                const active = highlightedIndex === index;

                return (
                  <li
                    className={classNames(
                      active ? "text-white bg-brand-light" : "text-gray-900",
                      "cursor-pointer select-none relative py-2 pl-3 pr-9"
                    )}
                    key={`${item.category}${item.name}${index}`}
                    // {...getItemProps({ item, index })}
                    {...getItemProps({
                      item,
                      index,
                    })}
                  >
                    <div className="flex">
                      <DocumentIcon
                        className={classNames(
                          active ? "text-white" : "text-gray-400",
                          "w-5 h-5 mr-3"
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">{item.name}</span>
                      <span
                        className={classNames(
                          highlightedIndex === index
                            ? "text-indigo-200"
                            : "text-gray-500",
                          "ml-2 truncate"
                        )}
                      >
                        {item.category}
                      </span>
                    </div>
                  </li>
                );
              })}
          </Transition>
        </ul>
      </div>
    </div>
  );
};
export default SearchProduct;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

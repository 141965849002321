import React from "react";
import { useFormik } from "formik";
import { Transition } from "@headlessui/react";
import { isEmail } from "../../utils/validations";
import useAuthentication from "../../hooks/useAuthentication";
import { getEnv } from "../../utils/helperFunctions";

export default function SignInForm() {
  const [login, loginInfo] = useAuthentication();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.email) {
        errors.email = "Email field is required";
      } else if (!isEmail(values.email)) {
        errors.email = "Invalid email format";
      }
      if (!values.password) {
        errors.password = "Password field is required";
      }

      return errors;
    },
    onSubmit: (values) => {
      login({ userName: values.email, password: values.password });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-6">
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-semibold text-deep-blue"
        >
          Email
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 text-ash"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
          </div>
          <input
            {...formik.getFieldProps("email")}
            type="text"
            name="email"
            id="email"
            autoComplete="email"
            className={`text-deep-blue placeholder-italic focus:ring-brand focus:border-brand block w-full pl-10 sm:text-sm border-gray-300 rounded-md ${
              (formik.touched.email && !!formik.errors.email) ?? false
                ? "border-blush"
                : ""
            }`}
            placeholder="you@example.com"
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <Transition
              show={formik.touched?.email === true && !!formik.errors.email}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-0"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-blush"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </Transition>
            <Transition
              show={
                formik.touched?.email === true &&
                formik.errors.email === undefined &&
                formik.values.email.length > 0
              }
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-0"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </Transition>
          </div>
        </div>
        <span className="block h-2 pt-1 text-xs italic text-red-400">
          <Transition
            show={(formik.touched.email && !!formik.errors.email) ?? false}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {formik.errors.email}
          </Transition>
        </span>
      </div>

      <div>
        <label
          htmlFor="password"
          className="block text-sm font-medium text-deep-blue"
        >
          Password
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-ash"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
              />
            </svg>
          </div>
          <input
            {...formik.getFieldProps("password")}
            type="password"
            name="password"
            id="password"
            autoComplete="password"
            className={`text-deep-blue placeholder-italic focus:ring-brand focus:border-brand block w-full pl-10 sm:text-sm border-gray-300 rounded-md ${
              (formik.touched.password && !!formik.errors.password) ?? false
                ? "border-red-300"
                : ""
            }`}
            placeholder=""
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <Transition
              show={
                (formik.touched.password && !!formik.errors.password) ?? false
              }
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-0"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-blush"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </Transition>
          </div>
        </div>
        <span className="block h-2 pt-1 text-xs italic text-blush">
          <Transition
            show={
              (formik.touched.password && !!formik.errors.password) ?? false
            }
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {formik.errors.password}
          </Transition>
        </span>
      </div>

      {/* <div className="flex justify-end">
        <div className="text-sm">
          <a
            href="/#"
            className="font-medium text-brand hover:text-brand-light"
          >
            Forgot your password?
          </a>
        </div>
      </div> */}

      <div>
        <button
          type="submit"
          className={`w-full flex justify-center py-2 px-4  border border-transparent rounded-md shadow-sm text-sm font-medium ${
            !(formik.isValid && formik.dirty) || loginInfo.isLoading
              ? "opacity-20 cursor-not-allowed"
              : ""
          } text-white bg-brand hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand`}
          disabled={!(formik.isValid && formik.dirty) || loginInfo.isLoading}
        >
          {!loginInfo.isLoading ? (
            "Sign in"
          ) : (
            <svg
              className="w-5 h-5 mr-3 text-white animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
        </button>
      </div>

      <Transition
        show={loginInfo.isError}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="p-4 mt-2 rounded-md bg-red-50">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="w-5 h-5 text-blush"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-deep-blue">
                Failed to validate your credentials.
              </h3>
            </div>
          </div>
        </div>
      </Transition>

      {/* {getEnv() !== "prod" && (
        <>
          <div className="relative opacity-80">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="px-3 text-sm font-medium text-gray-700 bg-white">
                Development & test quick login
              </span>
            </div>
          </div>

          <div className="flex opacity-80 ">
            <button
              title="user"
              type="button"
              className="mr-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() =>
                login({ userName: "johan@cykelombud.se", password: "abc123" })
              }
            >
              johan@cykelombud.se
            </button>

            <button
              title="admin"
              type="button"
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() =>
                login({ userName: "eric@evoli.se", password: "abc123" })
              }
            >
              eric@evoli.se
            </button>
          </div>
        </>
      )} */}
    </form>
  );
}
